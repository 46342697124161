<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="isLoggedIn" app clipped dark v-model="drawer">
      <v-list dense nav>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://cdn.vuetifyjs.com/images/logos/logo.svg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ $ml.get(userRoleName) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-for="item in items"
          :key="item.link"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $ml.get(item.title) }}</v-list-item-title>
          </v-list-item-content>

          <v-chip
            v-if="item.valid"
            :to="item.link + '?status=valid'"
            x-small
            color="green"
            >{{ item.valid }}</v-chip
          >
          <v-chip
            v-if="item.invalid"
            :to="item.link + '?status=valid'"
            x-small
            color="warning"
            >{{ item.invalid }}</v-chip
          >
        </v-list-item>

        <v-subheader>{{ $ml.get('work_settings') }}</v-subheader>

        <v-list-item
          v-for="item in itemsWorkSettings"
          :key="item.link"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $ml.get(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader>{{ $ml.get('reg_settings') }}</v-subheader>

        <v-list-item
          v-for="item in itemsRegSettings"
          :key="item.link"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $ml.get(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="isLoggedIn" clipped-left app dark dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Socrobotic FB</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div
        v-for="lang in $ml.list"
        :key="lang"
        :class="[{'active': $ml.current === lang}, 'lang', lang]"
        @click="saveLang(lang)"
      >
      </div>
      <v-btn icon large @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container v-bind:class="{ 'fill-height': !isLoggedIn }" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      user: {
        name: 'SomeName',
        role: 'SomeRole'
      },
      userRole: 'd',
      total_profiles: '',
      userName: 'SomeName',
      drawer: null,
      items: [
        { title: 'dashboard', link: '/dashboard', icon: 'mdi-view-dashboard' },
        {
          title: 'facebook',
          link: '/facebook',
          icon: 'mdi-facebook',
          valid: '100',
          invalid: '6',
        },
        {
          title: 'autoupload',
          link: '/autoupload',
          icon: 'mdi-briefcase-download',
        },
      ],
      itemsWorkSettings: [
        { title: 'farm', link: '/settings/farm', icon: 'mdi-robot' },
        { title: 'posting', link: '/settings/posting', icon: 'mdi-newspaper' },
        { title: 'сheckpoint', link: '/settings/checkpoint', icon: 'mdi-access-point-check' },
        { title: 'zrd', link: '/settings/zrd', icon: 'mdi-flash-alert' },
        {
          title: 'inviter',
          link: '/settings/inviter',
          icon: 'mdi-account-multiple-plus',
        },
        {
          title: 'publics_inviter',
          link: '/settings/inviter-public',
          icon: 'mdi-account-multiple-plus',
        },
        {
          title: 'messenger',
          link: '/settings/messenger',
          icon: 'mdi-chat-plus',
        },
        {
          title: 'data_for_work',
          link: '/data/job',
          icon: 'mdi-database',
        },
        {
          title: 'templates_auto_control_menu',
          link: '/settings/templates-auto-control',
          icon: 'mdi-application',
        },
      ],
      itemsRegSettings: [
        {
          title: 'registration',
          link: '/settings/accounts',
          icon: 'mdi-account-group',
        },
        {
          title: 'sms_services',
          link: '/settings/sms-services',
          icon: 'mdi-message-processing ',
        },
        {
          title: 'notifications',
          link: '/settings/notifications',
          icon: 'mdi-bell',
        },
        {
          title: 'proxies',
          link: '/data/proxy',
          icon: 'mdi-earth',
          digit: '93',
        },
        {
          title: 'reg_data',
          link: '/data/reg',
          icon: 'mdi-database',
        },
      ],
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    userRoleName() {
      if (this.$store.getters.getUserRole == 'admin') {
        return 'admin'
      }
      return 'Демонстрация'
    }
  },
  mounted() {
    this.getData(),
    this.$i18n.locale = localStorage.getItem('vueml-lang')
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/')
    },
    getData() {
      this.axios
        .post('stats/info')
        .then((response) => {
          this.items[1].valid = response.data.valid
          this.items[1].invalid = response.data.invalid
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    saveLang(lang) {
      this.$ml.change(lang)
      this.$i18n.locale = lang
      this.axios.post('config/set', {lang:lang})
    }
  },
  components: {},
  created() {
    this.interval = setInterval(() => this.getData(), 20000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
}
</script>
<style>
.lang {
  background: #fff;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  margin: 3px;
  padding: 3px;
}
.ru {
  background-image: url('./assets/ru.svg');
}
.es {
  background-image: url('./assets/Esp.svg');
}
.us {
  background-image: url('./assets/En.svg');
}
.active {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}
</style>
