import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    userRole: localStorage.getItem('userRole') || '',
    fields: {},
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, authData) {
      state.status = 'success'
      state.token = authData.token
      state.fields = authData.fields
      state.userRole = authData.userRole
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.userRole = ''
      state.token = ''
    },
    removeToken(state) {
      state.token = ''
      state.satus = ''
    }
  },
  actions: {
    login({ commit }, fields) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: 'auth/login', data: fields, method: 'POST' })
          .then(resp => {
            const authData = {
              token: resp.data.token,
              fields: resp.data.fields,
              userRole: resp.data.role
            }

            localStorage.setItem('userRole', authData.userRole)
            localStorage.setItem('token', authData.token)

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authData.token

            commit('auth_success', authData)

            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        axios({ url: 'auth/logout', method: 'POST' })
        localStorage.removeItem('token')
        localStorage.removeItem('userRole')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    removeToken({ commit }) {
      commit('removeToken')
      localStorage.removeItem('token')
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUserRole: state => state.userRole
  }
})
