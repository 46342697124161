import { render, staticRenderFns } from "./Messenger.vue?vue&type=template&id=71a4e0bb&"
import script from "./Messenger.vue?vue&type=script&lang=js&"
export * from "./Messenger.vue?vue&type=script&lang=js&"
import style0 from "./Messenger.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VRadio,VRadioGroup,VRangeSlider,VSlider,VSwitch,VTextField,VTextarea})
