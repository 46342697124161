import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "ru",
  save: true,
  languages: [
    new MLanguage("ru").create({
      total: "Всего",
      language: "Рус",
      valid: "Валидных",
      invalid: "Отлетело",
      addFriends: "Добавлено в друзья:",
      sentMessages: "Отправлено сообщений:",
      status: "Статус",
      number: "Количество",
      autoupload: "Автозалив",
      facebook: "Facebook",
      dashboard: "Dashboard",
      cardslist: "Список карт",
      company: "Компания",
      creative_folder_path: "Путь к папке с креативами",
      accounts: "Аккаунты",
      sortby: "Сортировать по...",
      onpage: "На странице",
      enable: "Включить",
      disable: "Выключить",
      all: "все",
      download: "Скачать",
      upload: "Загрузить",
      delete: "Удалить",
      selected: "выбранные",
      friends: "Друзья",
      city: "Город",
      days_from_reg: "Дней с регистрации",
      login: "Login",
      birthday: "День рождения",
      account: "аккаунт",
      pers_ads_manager: "РК ЛИЧКА",
      bm: "БМ",
      invite_link: "Ссылка для инвайта",
      token: "Токен",
      selfie_photo: "Фото для селфи",
      farm_settings: "Настройка фарма",
      del_phone: "Удалять номер телефона с аккаунта",
      streams: "Потоки",
      dis_acc: "На какой день выключать аккаунт",
      checkpoint: "Checkpoint",
      change_proxy_inv: "Менять прокси, если невалидный",
      appr_friends: "Одобрять друзей при фарме",
      count_appr_friends: "Сколько друзей одобрять",
      interval_betw_act: "Интервал между действиями",
      launch_accounts: "Как часто запускать аккаунты",
      emulate_mouse: "Эмулировать мышь",
      create: "Создавать",
      names: "Названия",
      create_date: "На какой день создавать",
      fill_posts: "Наполнять FP постами с",
      posts_count: "Сколько постов делать",
      how_solve_captcha: "Чем разгадывать капчу",
      sites_posts: "Постить посты с разных сайтов",
      walking_cookies: "Нагул кук",
      browse_sites: "Гулять по сайтам",
      count_browse_sites: "Сколько сайтов посещать, нагуливать",
      additionally: "Дополнительно",
      rebind_desktop_ua: "Делать перепривязку на десктопный ЮА",
      rebinding_day: "На какой день делать перепривязку",
      accs_to_friend: "Аккаунты для добавления в друзья",
      renew_friends: "Передруживать друзей",
      friendship_day: "День старта добавления в друзья",
      friends_number: "Количество друзей",
      fakes_number: "Сколько своих фейков добавлять за раз",
      login_to_sites: "Залогиниваться на сайтах",
      authorization_sites: "Сайты для авторизации",
      start_day: "День старта",
      logins_number: "Количество логинов",
      spam_bot: "Спам-бот",
      enable_spam_bot: "Включить спам-бота",
      threads_number: "Количество потоков",
      spam_bot_runs: "Как часто запускать спам-бот",
      posting_setup: "Настройка постинга",
      posting: "Постинг",
      friends_number_to_start: "Количество друзей для старта",
      wall_posts_text: "Текст для постов на стену",
      post_link_text: "Постить ссылку и текст в профиль",
      biography_text: "Текст для биографии",
      links_groups_publics: "Ссылки на группы/паблики для репостов",
      like_posts: "Лайкать посты",
      like_post: "Ставить лайк посту",
      share_posts: "Расшарить посты",
      section: "Раздел",
      comment_post: "Писать комментарий под постом",
      comment_text: "Текст для комментариев",
      dating_groups_post: "Делать посты в группах для знакомств",
      checkpoint_settings: "Настройки чекпоинта",
      receive_accounts_phone: "Получать номер телефона аккаунтам слетевшим на телефон",
      account_friends: "Должны ли быть друзья на аккаунте",
      days_since_reg: "Дней с момента регистрации",
      zrd_settings: "Настройки ЗРД",
      zrd_eaab: "Проходить ЗРД (Получать токен ЕААВ)",
      zrd_check_period: "Как часто проверять проход ЗРД",
      zrd_only: "Проходит только ЗРД",
      get_number: "Получать номер",
      try_create_bm: "Пробовать создать БМ",
      fp_only: "Создавать только ФП",
      take_docs_from: "Брать доки с",
      software_render: "Отрисовывать доки софтом",
      docs_from_folder: "Брать доки из своей папки",
      backgrounds: "Устанавливать свои фоны (необходимо разрешение 1500x1500)",
      folder_path: "Укажите путь к файлу с папкой",
      inviter_setting: "Настройка инвайтера",
      inviter: "Инвайтер",
      inviter_launch_day: "На какой день запускать инвайтер",
      check_white_list: "Проверять white list имен",
      check_black_list: "Проверять Black list имен",
      public_inviter: "Инвайтер в паблик",
      public_link: "Ссылка на паблик",
      public_inviter_text: "Текст приглашения в паблик",
      group_links: "Ссылки на группы и приглашения",
      messenger_setting: "Настройка мессенджера",
      messenger_enable: "Включить мессенджер",
      max_messages_per_person: "Максимально количество сообщений на человека",
      number_of_messages: "Сколько всего писать сообщений",
      interval: "Интервал",
      interval_check_messages: "Интервал проверки новых сообщений",
      number_of_check: "Сколько раз проверять непрочитанные",
      bot_name: "Название бота",
      bot_language: "Язык общения бота",
      message: "Сообщение",
      data: "Данные",
      delete_all_entries: "Удалить все записи",
      proxies: "Прокси",
      proxies_unload: "Какие выгружать прокси?",
      proxy_import: "Импорт прокси",
      proxy_formats: "Допустимые форматы прокси:",
      link_for_reconnect: "ссылка_для_реконнекта",
      link_for_reconnect_notification: "Ссылку для реконекта указывать нельзя!",
      proxy_socks5: "Если прокси socks5 то необходимо указать протокол socks5://",
      proxy_txt: "Файл txt с прокси",
      one_proxy_streams: "Потоков на одной проксе",
      total_lines: "Всего строк",
      total_proxy: "Всего прокси",
      close: "Закрыть",
      sms_services: "SMS Сервисы",
      phone_based_on_ip: "Брать телефон исходя из ip адреса",
      sms_waiting_time: "Время ожидания смс",
      number_several_times_reg: "Использовать один номер несколько раз для регистрации",
      number_several_times_unlock: "Использовать один номер несколько раз для разблокировки",
      countries: "Страны",
      more: "еще",
      chat_id: "Чат id",
      logs_to_db: "Писать логи в БД",
      logs_to_telegram: "Отправлять все логи в телегу",
      notifications_for_reg: "Оповещения по регеру",
      notifications_for_warm: "Оповещения по warm",
      notifications_for_chat_bot: "Оповещения для чат бота",
      registration: "Регистрация",
      wait: "Отлежка",
      reg_time: "Время регистрации",
      reg_around_clock: "Регистрировать круглосуточно",
      reg_method: "Способ регистрации",
      rebind_service_number: "Перепривязывать на номер с сервиса",
      rebind_to_mail: "Перепривязывать на почту",
      receive_phone_banned_reg: "Получать номер телефона при бане аккаунта в момент регистрации",
      mail_from_service: "Брать почты с сервиса",
      key: "ключ",
      check_valid: "Проверять валидность",
      filling: "Заполнение",
      photo_from: "Фото с",
      filling_inf: "Заполнять информацию",
      fill_based_ip: "Заполнять исходя из текущего ip адреса",
      education: "Образование",
      work: "Работа",
      posts: "Посты",
      posts_num_at_reg: "Количество постов на стене при регистрации",
      friends_when_reg: "Добавлять в друзья при регистрации",
      sites: "Сайты",
      sites_before_reg: "Сколько сайтов посещать перед заходом на форму регистрации",
      gender_selection: "Выбор пола",
      male: "Мужской",
      female: "Женский",
      authorization: "Авторизация",
      sign_in: "Войти",
      password: "Пароль",
      work_settings: "Настройки работы",
      reg_settings: "Настройки регистрации",
      farm: "Фарм",
      сheckpoint: "Чекпоинт",
      zrd: "ЗРД",
      publics_inviter: "Инвайтер в паблики",
      messenger: "Мессенджер",
      data_for_work: "Данные для работы",
      notifications: "Оповещения",
      reg_data: "Данные для регистрации",
      admin: "Администратор",
      filter: "Фильтр",
      statuses: "Статусы",
      geo: "Гео",
      import_accs_from_logs: "Импорт аккаунтов из логов",
      logs: "Логи",
      files_left: "еще файлов",
      import: "Импортировать",
      waiting: "Подождите",
      settings: "Настройки",
      enter_company: "Укажите компанию",
      pictures_folder_path: "Путь к папке с картинками",
      groups_of_group_field: "Поле для ввода пачки групп",
      text_input_field: "Поле для ввода текста",
      generate_phone: "Генерировать телефон",
      templates_auto_control_menu: "Автоматизация сервера",
      templates_auto_control: "Автоматическое управление шаблонами в Zennoposter",
      registrator: "Регистратор",
      unban_accounts_blocked_status: 'Получать номер телефона аккаунтам в статусе: "Заблокирован рекламный кабинет навсегда"',
      general_server_options: 'Общие настройки',
      remove_invalid_accounts: 'Удалять невалидные аккаунты',
      disable_hand_mode: 'Выключить ручной режим',
      enable_hand_mode: 'Включить ручно режим'
    }),

    new MLanguage("us").create({
      total: "Total",
      language: "En",
      valid: "Valid",
      invalid: "Not valid",
      addFriends: "Added to friends:",
      sentMessages: "Messages sent:",
      status: "Status",
      number: "Number",
      autoupload: "Autoupload",
      facebook: "Facebook",
      dashboard: "Dashboard",
      cardslist: "Cards list",
      company: "Company",
      creative_folder_path: "Creatives folder path",
      accounts: "Accounts",
      sortby: "Sort by...",
      onpage: "On the page",
      enable: "Enable",
      disable: "Disable",
      all: "all",
      download: "Download",
      upload: "Upload",
      delete: "Delete",
      selected: "selected",
      friends: "Friends",
      city: "City",
      days_from_reg: "Days from registration",
      login: "Login",
      birthday: "Birthday",
      account: "account",
      pers_ads_manager: "personal ads manager",
      bm: "BM",
      invite_link: "Invite link",
      token: "Token",
      selfie_photo: "Selfie photo",
      farm_settings: "Farm settings",
      del_phone: "Remove phone number from account",
      streams: "Streams",
      dis_acc: "Day to disable account",
      checkpoint: "Checkpoint",
      change_proxy_inv: "Change proxy if invalid",
      appr_friends: "Approve friends when farming",
      count_appr_friends: "How many friends to approve",
      interval_betw_act: "Interval between actions",
      launch_accounts: "How often to launch accounts",
      emulate_mouse: "Emulate mouse",
      create: "Create",
      names: "Names",
      create_date: "What day to create",
      fill_posts: "Fill FP with posts with",
      posts_count: "How many posts to make",
      how_solve_captcha: "How to solve captcha",
      sites_posts: "Post posts from different sites",
      walking_cookies: "Walking cookies",
      browse_sites: "Browse sites",
      count_browse_sites: "How many sites to visit",
      additionally: "Additionally",
      rebind_desktop_ua: "Rebind to desktop UA",
      rebinding_day: "Rebinding day",
      accs_to_friend: "Accounts to add to friends",
      renew_friends: "Renewing friendships",
      friendship_day: "Friendship start day",
      friends_number: "Number of friends",
      fakes_number: "How many of your fakes to add at a time",
      login_to_sites: "Login to sites",
      authorization_sites: "Authorization sites",
      start_day: "Start day",
      logins_number: "Number of logins",
      spam_bot: "Spam bot",
      enable_spam_bot: "Enable spam bot",
      threads_number: "Number of threads",
      spam_bot_runs: "How often to run a spam bot",
      posting_setup: "Posting setup",
      posting: "Posting",
      friends_number_to_start: "Number of friends to start",
      wall_posts_text: "Text for posts on the wall",
      post_link_text: "Post link and text to profile",
      biography_text: "Text for biography",
      links_groups_publics: "Links to groups / publics for reposts",
      like_posts: "To like posts",
      like_post: "To like post",
      share_posts: "Share posts",
      section: "Section",
      comment_post: "Comment on the post",
      comment_text: "Comment text",
      dating_groups_post: "Post in dating groups",
      checkpoint_settings: "Checkpoint settings",
      receive_accounts_phone: "Receive a phone number for accounts flown to the phone",
      account_friends: "Should there be friends on the account",
      days_since_reg: "Days since registration",
      zrd_settings: "ZRD settings",
      zrd_eaab: "Pass ZRD (Receive EAAB token)",
      zrd_check_period: "How often to check the passage of the ZRD",
      zrd_only: "Only ZRD passes",
      get_number: "Get a number",
      try_create_bm: "Try to create BM",
      fp_only: "Create FP only",
      take_docs_from: "Take docs from",
      software_render: "Render docs with software",
      docs_from_folder: "Take docs from your folder",
      backgrounds: "Set your backgrounds (1500x1500 resolution required)",
      folder_path: "Enter the path to the folder",
      inviter_setting: "Setting up the inviter",
      inviter: "Inviter",
      inviter_launch_day: "Inviter launch day",
      check_white_list: "Check white list of names",
      check_black_list: "Check black list of names",
      public_inviter: "Blog page inviter",
      public_link: "Blog page link",
      public_inviter_text: "Inviter text to the public page",
      group_links: "Group links and invitations",
      messenger_setting: "Setting the messenger",
      messenger_enable: "Enable messenger",
      max_messages_per_person: "Maximum number of messages per person",
      number_of_messages: "How many messages to write",
      interval: "Interval",
      interval_check_messages: "The interval to check for new messages",
      number_of_check: "How many times to check unread",
      bot_name: "Bot name",
      bot_language: "Bot communication language",
      message: "Message",
      data: "Data",
      delete_all_entries: "Delete all entries",
      proxies: "Proxies",
      proxies_unload: "What proxies to unload?",
      proxy_import: "Proxy import",
      proxy_formats: "Allowed proxy formats:",
      link_for_reconnect: "link_for_reconnect",
      link_for_reconnect_notification: "You can't use reconnect link",
      proxy_socks5: "If the proxy socks5 then you must specify the protocol socks5: //",
      proxy_txt: "Txt file with the proxy",
      one_proxy_streams: "Streams on one proxy",
      total_lines: "Total lines",
      total_proxy: "Total proxy",
      close: "Close",
      sms_services: "SMS Services",
      phone_based_on_ip: "Take the phone based on the ip address",
      sms_waiting_time: "SMS waiting time",
      number_several_times_reg: "Use one number several times for registration",
      number_several_times_unlock: "Use one number several times to unlock",
      countries: "Countries",
      more: "more",
      chat_id: "Chat id",
      logs_to_db: "Write logs to the database",
      logs_to_telegram: "Send all logs to telegram",
      notifications_for_reg: "Registrar notifications",
      notifications_for_warm: "Warm notifications",
      notifications_for_chat_bot: "Chat bot notifications",
      registration: "Registration",
      wait: "To wait",
      reg_time: "Registration time",
      reg_around_clock: "Register around the clock",
      reg_method: "Registration method",
      rebind_service_number: "Rebind to a number from the service",
      rebind_to_mail: "Rebind to mail",
      receive_phone_banned_reg: "Receive a phone number when the account is banned at the time of registration",
      mail_from_service: "Take mail from the service",
      key: "key",
      check_valid: "Check validity",
      filling: "Filling",
      photo_from: "Photo from",
      filling_inf: "Fill in information",
      fill_based_ip: "Fill based on the current ip address",
      education: "Education",
      work: "Work",
      posts: "Posts",
      posts_num_at_reg: "Number of posts on the wall at registration",
      friends_when_reg: "Add to friends when registering",
      sites: "Websites",
      sites_before_reg: "How many sites to visit before entering the registration form",
      gender_selection: "Gender selection",
      male: "Male",
      female: "Female",
      authorization: "Authorization",
      sign_in: "Sign in",
      password: "Password",
      work_settings: "Work settings",
      reg_settings: "Registration settings",
      farm: "Farm",
      сheckpoint: "Checkpoint",
      zrd: "ZRD",
      publics_inviter: "Inviter in the Publics",
      messenger: "Messenger",
      data_for_work: "Data for work",
      notifications: "Notifications",
      reg_data: "Registration data",
      admin: "Admin",
      filter: "Filter",
      statuses: "Statuses",
      geo: "Geo",
      import_accs_from_logs: "Importing accounts from logs",
      logs: "Logs",
      files_left: "files left",
      import: "Import",
      waiting: "Wait",
      settings: "Settings",
      enter_company: "Enter company",
      pictures_folder_path: "The path to the folder with pictures",
      groups_of_group_field: "Field for entering a group of groups",
      text_input_field: "Text input field",
      generate_phone: "Generate phone",
      templates_auto_control_menu: "Server automation",
      templates_auto_control: "Automatic template management in Zennoposter",
      registrator: "Registrar",
      unban_accounts_blocked_status: 'Receive a phone number for accounts in the status: "Advertising account is blocked forever"',
      general_server_options: 'General settings',
      remove_invalid_accounts: 'Remove invalid accounts',
      disable_hand_mode: 'Выключить ручной режим',
      enable_hand_mode: 'Включить ручно режим'
    }),

    new MLanguage("es").create({
      total: "Total",
      language: "Esp",
      valid: "Válido",
      invalid: "No válido",
      addFriends: "Agregado a amigos:",
      sentMessages: "Mensajes enviados:",
      status: "Estado",
      number: "Cantidad",
      autoupload: "Carga automática",
      facebook: "Facebook",
      dashboard: "Dashboard",
      cardslist: "Lista de cartas",
      company: "Empresa",
      creative_folder_path: "Ruta de la carpeta de creatividades",
      accounts: "Cuentas",
      sortby: "Ordenar por...",
      onpage: "En la pagina",
      enable: "Permitirlos",
      disable: "Apagar",
      all: "todo",
      download: "Descargar",
      upload: "Subir",
      delete: "Borrar",
      selected: "seleccionado",
      friends: "Amigos",
      city: "Ciudad",
      days_from_reg: "Días desde el registro",
      login: "Acceso",
      birthday: "Cumpleaños",
      account: "cuenta",
      pers_ads_manager: "administrador de anuncios personales",
      bm: "GN",
      invite_link: "Enlace para invitación",
      token: "Simbólico",
      selfie_photo: "Foto de selfie",
      farm_settings: "Configuración de la granja",
      del_phone: "Eliminar el número de teléfono de la cuenta",
      streams: "Corrientes",
      dis_acc: "Día para deshabilitar la cuenta",
      checkpoint: "Punto de control",
      change_proxy_inv: "Cambiar proxy si no es válido",
      appr_friends: "Aprobar amigos cuando cultives",
      count_appr_friends: "Cuantos amigos aprobar",
      interval_betw_act: "Intervalo entre acciones",
      launch_accounts: "Con que frecuencia lanzar cuentas",
      emulate_mouse: "Emular ratón",
      create: "Crear",
      names: "Nombres",
      create_date: "Que dia crear",
      fill_posts: "Llenar FP con publicaciones con",
      posts_count: "Cuantas publicaciones hacer",
      how_solve_captcha: "Cómo resolver captcha",
      sites_posts: "Publica publicaciones de diferentes sitios",
      walking_cookies: "Andar cookies",
      browse_sites: "Navega por los sitios",
      count_browse_sites: "Cuantos sitios visitar",
      additionally: "Adicionalmente",
      rebind_desktop_ua: "Volver a vincular a UA de escritorio",
      rebinding_day: "Día de la restitución",
      accs_to_friend: "Cuentas para agregar a amigos",
      renew_friends: "Renovar la amistad",
      friendship_day: "Día de inicio de la amistad",
      friends_number: "Numero de amigos",
      fakes_number: "Añadir el número de falsificaciones en un momento",
      login_to_sites: "Iniciar sesión en sitios",
      authorization_sites: "Sitios de autorización",
      start_day: "Día de inicio",
      logins_number: "Número de inicios de sesión",
      spam_bot: "Bot de spam",
      enable_spam_bot: "Habilitar bot de spam",
      threads_number: "Número de hilos",
      spam_bot_runs: "Con que frecuencia ejecutar un bot de spam",
      posting_setup: "Configuración de publicación",
      posting: "Publicación",
      friends_number_to_start: "Número de amigos para empezar",
      wall_posts_text: "Texto para mensajes en la pared",
      post_link_text: "Publicar enlace y texto en el perfil",
      biography_text: "Texto para biografía",
      links_groups_publics: "Los enlaces a grupos / públicos para reposts",
      like_posts: "Para dar me gusta publicaciones",
      like_post: "Para dar me gusta publicaciones",
      share_posts: "Compartir publicaciones",
      section: "Sección",
      comment_post: "Comenta en la publicación",
      comment_text: "Texto de comentario",
      dating_groups_post: "Publicar en grupos de citas",
      checkpoint_settings: "Configuración del punto de control",
      receive_accounts_phone: "Reciba un número de teléfono para las cuentas enviadas al teléfono",
      account_friends: "Debería haber amigos en la cuenta",
      days_since_reg: "Días desde el registro",
      zrd_settings: "Configuración de ZRD",
      zrd_eaab: "Pase ZRD (reciba el token EAAB)",
      zrd_check_period: "Con qué frecuencia verificar el paso de la ZRD",
      zrd_only: "Solo pases ZRD",
      get_number: "Consigue un numero",
      try_create_bm: "Intenta crear BM",
      fp_only: "Crear FP solamente",
      take_docs_from: "Tomar documentos de",
      software_render: "Renderizar documentos con software",
      docs_from_folder: "Toma documentos de tu carpeta",
      backgrounds: "Configure sus fondos (se requiere una resolución de 1500x1500)",
      folder_path: "Especifique la ruta al archivo con la carpeta",
      inviter_setting: "Configurar el invitador",
      inviter: "Invitador",
      inviter_launch_day: "El día de lanzar el invitador",
      check_white_list: "Verifique la lista blanca de nombres",
      check_black_list: "Compruebe la lista negra de nombres",
      public_inviter: "Invitador de la página del blog",
      public_link: "Vínculo de la página del blog",
      public_inviter_text: "Invitar texto a la página pública",
      group_links: "Enlaces e invitaciones grupales",
      messenger_setting: "Configurando el mensajero",
      messenger_enable: "Habilitar messenger",
      max_messages_per_person: "Número máximo de mensajes por persona",
      number_of_messages: "Cuantos mensajes escribir",
      interval: "Intervalo",
      interval_check_messages: "El intervalo de comprobación de nuevos mensajes",
      number_of_check: "Cuántas veces comprobar los mensajes no leídos",
      bot_name: "Nombre del bot",
      bot_language: "Lenguaje de comunicación del bot",
      message: "Mensaje",
      data: "Datos",
      delete_all_entries: "Eliminar todas las entradas",
      proxies: "Apoderados",
      proxies_unload: "¿Qué proxies descargar?",
      proxy_import: "Importación de proxy",
      proxy_formats: "Formatos de proxy permitidos:",
      link_for_reconnect: "enlace_para_reconectar",
      link_for_reconnect_notification: "No puedes usar el enlace reconectar",
      proxy_socks5: "Si el proxy es socks5, debe especificar el protocolo socks5: //",
      proxy_txt: "Archivo txt proxy",
      one_proxy_streams: "Transmisiones en un proxy",
      total_lines: "Líneas totales",
      total_proxy: "Proxy total",
      close: "Cerca",
      sms_services: "Servicios SMS",
      phone_based_on_ip: "Elija un teléfono basado en la dirección IP",
      sms_waiting_time: "Tiempo de espera de SMS",
      number_several_times_reg: "Utilice un número varias veces para registrarse",
      number_several_times_unlock: "Usa un número varias veces para desbloquear",
      countries: "Países",
      more: "más",
      chat_id: "ID de chat",
      logs_to_db: "Registros de escritura en DB",
      logs_to_telegram: "Enviar todos los registros a telegram",
      notifications_for_reg: "Notificaciones del registrador",
      notifications_for_warm: "Notificaciones del warm",
      notifications_for_chat_bot: "Notificaciones de bot de chat",
      registration: "Registrarse",
      wait: "Esperar",
      reg_time: "Tiempo de registro",
      reg_around_clock: "Regístrese las 24 horas",
      reg_method: "Método de registro",
      rebind_service_number: "Vuelva a enlazar a un número del servicio",
      rebind_to_mail: "Volver a enlazar al correo",
      receive_phone_banned_reg: "Reciba un número de teléfono cuando la cuenta esté prohibida en el momento del registro",
      mail_from_service: "Recibir correo del servicio",
      key: "clave",
      check_valid: "Verificar validez",
      filling: "Relleno",
      photo_from: "Foto del",
      filling_inf: "Completa la información",
      fill_based_ip: "Llenar según la dirección IP actual",
      education: "Educación",
      work: "Trabaja",
      posts: "Publicaciones",
      posts_num_at_reg: "Número de publicaciones en el muro al registrarse",
      friends_when_reg: "Agregar a amigos al registrarse",
      sites: "Sitios web",
      sites_before_reg: "Cuántos sitios visitar antes de ingresar al formulario de registro",
      gender_selection: "Selección de género",
      male: "Masculino",
      female: "Mujer",
      authorization: "Autorización",
      sign_in: "Entrar",
      password: "Contraseña",
      work_settings: "Entorno de trabajo",
      reg_settings: "Configuración de registro",
      farm: "Granja",
      сheckpoint: "Checkpoint",
      zrd: "ZRD",
      publics_inviter: "Invitador en los públicos",
      messenger: "Mensajero",
      data_for_work: "Datos para el trabajo",
      notifications: "Notificaciones",
      reg_data: "Datos de registro",
      admin: "Admin",
      filter: "Filtrar",
      statuses: "Estados",
      geo: "Geo",
      import_accs_from_logs: "Importar cuentas de registros",
      logs: "Logs",
      files_left: "archivos a la izquierda",
      import: "Importar",
      waiting: "Esperar",
      settings: "Configuración",
      enter_company: "Indique la empresa",
      pictures_folder_path: "La ruta a la carpeta con imágenes",
      groups_of_group_field: "Campo para ingresar a un grupo de grupos",
      text_input_field: "Campo de entrada de texto",
      generate_phone: "Generar teléfono",
      templates_auto_control_menu: "Automatización del servidor",
      templates_auto_control: "Gestión automática de plantillas en Zennoposter",
      registrator: "Registrador",
      unban_accounts_blocked_status: 'Reciba un número de teléfono para las cuentas con el estado: "La cuenta de publicidad está bloqueada para siempre"',
      general_server_options: 'Configuración general',
      remove_invalid_accounts: 'Eliminar cuentas inválidas',
      disable_hand_mode: 'Deshabilitar el modo manual',
      enable_hand_mode: 'Habilitar el modo manual'
    })
  ]
});
