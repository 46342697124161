<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-chat-plus</v-icon>
      <span class="title font-weight-light">{{ $ml.get('messenger_setting') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch v-model="fields.messenger" :loading="loading.messenger">
        <template v-slot:label>
          {{ $ml.get('messenger_enable') }}
          <sb-hint v-if="hints.messenger">{{ hints.messenger }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        v-if="fields.messenger"
        v-model="fields.message_max"
        :min="1"
        :max="7"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('max_messages_per_person') }}
          <sb-hint v-if="hints.message_max">{{ hints.message_max }}</sb-hint>
        </template>
      </v-slider>

      <v-slider
        v-if="fields.messenger"
        v-model="fields.message_total"
        :min="1"
        :max="1000"
        persistent-hint
        step="50"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('number_of_messages') }}
          <sb-hint v-if="hints.message_total">{{
            hints.message_total
          }}</sb-hint>
        </template>
      </v-slider>

      <v-range-slider
        v-if="fields.messenger"
        v-model="fields.message_interval"
        :min="1"
        :max="60"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('interval') }}
          <sb-hint v-if="hints.message_interval">{{
            hints.message_interval
          }}</sb-hint>
        </template>
      </v-range-slider>

      <v-slider
        v-if="fields.messenger"
        v-model="fields.message_check_new"
        :min="1"
        :max="10"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('interval_check_messages') }}
          <sb-hint v-if="hints.message_check_new">{{
            hints.message_check_new
          }}</sb-hint>
        </template>
      </v-slider>

      <v-slider
        v-if="fields.messenger"
        v-model="fields.message_check_unread"
        :min="1"
        :max="10"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('number_of_check') }}
          <sb-hint v-if="hints.message_check_unread">{{
            hints.message_check_unread
          }}</sb-hint>
        </template>
      </v-slider>

      <v-switch
        v-if="fields.messenger"
        v-model="fields.dialog_flow"
        :loading="loading.dialog_flow"
        label="DialogFlow"
      >
        <template v-slot:label>
          DialogFlow
          <sb-hint v-if="hints.dialog_flow">{{ hints.dialog_flow }}</sb-hint>
        </template>
      </v-switch>

      <div
        v-if="fields.messenger && fields.dialog_flow"
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            DialogFlow API key
            <sb-hint v-if="hints.dialog_flow_api_key">{{
              hints.dialog_flow_api_key
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-text-field
            v-model="fields.dialog_flow_api_key"
            :loading="loading.dialog_flow_api_key"
          >
          </v-text-field>
        </div>
      </div>

      <div
        v-if="fields.messenger && fields.dialog_flow"
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            DialogFlow {{ $ml.get('bot_name') }}
            <sb-hint v-if="hints.dialog_flow_bot_name">{{
              hints.dialog_flow_bot_name
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-text-field
            v-model="fields.dialog_flow_bot_name"
            :loading="loading.dialog_flow_bot_name"
          >
          </v-text-field>
        </div>
      </div>

      <v-radio-group
        v-if="fields.messenger && fields.dialog_flow"
        v-model="fields.dialog_flow_bot_lang"
        :loading="loading.dialog_flow_bot_lang"
        row
      >
        <div class="op-6 mr-4 pl-0">
          DialogFlow {{ $ml.get('bot_language') }}
          <sb-hint v-if="hints.dialog_flow_bot_lang">{{
            hints.dialog_flow_bot_lang
          }}</sb-hint>
        </div>
        <v-radio label="Русский" value="rus"></v-radio>
        <v-radio label="Английски" value="eng"></v-radio>
      </v-radio-group>

      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #1
            <sb-hint v-if="hints.message_tempate_1">{{
              hints.message_tempate_1
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_1"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #2
            <sb-hint v-if="hints.message_tempate_2">{{
              hints.message_tempate_2
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_2"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #3
            <sb-hint v-if="hints.message_tempate_3">{{
              hints.message_tempate_3
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_3"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #4
            <sb-hint v-if="hints.message_tempate_4">{{
              hints.message_tempate_4
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_4"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #5
            <sb-hint v-if="hints.message_tempate_5">{{
              hints.message_tempate_5
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_5"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #6
            <sb-hint v-if="hints.message_tempate_6">{{
              hints.message_tempate_6
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_6"> </v-textarea>
        </div>
      </div>
      <div v-if="fields.messenger" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('message') }} #7
            <sb-hint v-if="hints.message_tempate_7">{{
              hints.message_tempate_7
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.message_tempate_7"> </v-textarea>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        messenger: false,
        message_max: '',
        message_total: '',
        message_interval: ['1', '5'],
        message_check_new: '',
        message_check_unread: '',
        dialog_flow: '',
        dialog_flow_api_key: '',
        dialog_flow_bot_name: '',
        dialog_flow_bot_lang: '',
        message_tempate_1: '',
        message_tempate_2: '',
        message_tempate_3: '',
        message_tempate_4: '',
        message_tempate_5: '',
        message_tempate_6: '',
        message_tempate_7: '',
      },
      loading: {
        messenger: false,
        message_max: false,
        message_total: false,
        message_interval: false,
        message_check_new: false,
        message_check_unread: false,
        dialog_flow: false,
        dialog_flow_api_key: false,
        dialog_flow_bot_name: false,
        dialog_flow_bot_lang: false,
        message_tempate_1: false,
        message_tempate_2: false,
        message_tempate_3: false,
        message_tempate_4: false,
        message_tempate_5: false,
        message_tempate_6: false,
        message_tempate_7: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'messenger' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'messenger',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>

<style lang="sass">
.op-6
  opacity: .7
</style>
