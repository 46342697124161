import Section from '../views/Data/Section.vue'
import List from '../views/Data/List.vue'
import Proxies from '../views/Data/Proxy.vue'

const routes = [
  {
    path: '/data/proxy',
    name: 'Proxy',
    component: Proxies,
    meta: {
      title: 'Данные - Прокси',
      requiresAuth: true
    }
  },
  {
    path: '/data/:section',
    name: 'Section',
    component: Section,
    meta: {
      title: 'Данные',
      requiresAuth: true
    }
  },
  {
    path: '/data/:section/:table',
    name: 'List',
    component: List,
    meta: {
      title: 'Данные',
      requiresAuth: true
    }
  },
]

export default routes
