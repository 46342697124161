<template>
  <v-row justify="start" align="start">
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :server-items-length="totalItems"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title
              >{{ sectionName }} ({{ totalItems }})</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="downloadFile"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('download') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="uploadFile"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('upload') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="red"
                  @click="resetTable"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('delete_all_entries') }}</span>
            </v-tooltip>
          </v-toolbar>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>

      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".txt, .csv"
        @change="onFileChanged"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      totalItems: 0,
      items: [],
      loading: true,
      sectionName: '',
      options: {},
      headers: [
        { text: '#', align: 'start', value: 'id', width: 70 },
        { text: 'Значение', align: 'start', value: 'value' },
      ],
      snackbar: {
        status: false,
        text: '',
        color: 'green',
      },
      page: 1,
      pageCount: 1,
      itemsPerPage: 5,
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Добавить' : 'Редактировать'
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    page() {
      this.getDataFromApi()
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true

      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
        page: this.page,
      }

      if (this.options.sortBy != 0) {
        data.sort = this.options.sortBy[0]

        if (this.options.sortDesc[0]) {
          data.sort_order = 'desc'
        } else {
          data.sort_order = 'asc'
        }
      }

      this.axios.post('data/list', data).then((response) => {
        this.items = response.data.data
        this.totalItems = response.data.total
        this.pageCount = response.data.total_page
        this.sectionName = response.data.section_name
        this.loading = false
      })
    },
    uploadFile() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },
    downloadFile() {
      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
      }

      this.axios
        .post('data/download', data, {
          responseType: 'blob',
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', this.$route.params.table + '.csv')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    resetTable() {
      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
      }

      this.axios.post('data/remove-all', data).then(() => {
        this.getDataFromApi()
      })
    },
    onFileChanged(e) {
      let formData = new FormData()

      formData.append('section', this.$route.params.section)
      formData.append('url', this.$route.params.table)
      formData.append('file', e.target.files[0])

      this.axios
        .post('data/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          console.log(response)
          this.getDataFromApi()
        })

      this.$refs.uploader.value = null

      console.log('File changed')
    },
    getColor(status) {
      if (status == 'success') {
        return 'green'
      } else {
        return 'orange'
      }
    },
  },
}
</script>
