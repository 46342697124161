<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon large left>mdi-application</v-icon>
        <span class="title font-weight-light">{{
          $ml.get('templates_auto_control')
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-switch
          class="mt-0"
          v-model="fields.template_registrator"
          :loading="loading.template_registrator"
        >
          <template v-slot:label>
            {{ $ml.get('registrator') }}
            <sb-hint v-if="hints.template_registrator">{{
              hints.template_registrator
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.template_worm"
          :loading="loading.template_worm"
        >
          <template v-slot:label>
            Worm
            <sb-hint v-if="hints.template_worm">{{
              hints.template_worm
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.template_worm_bm"
          :loading="loading.template_worm_bm"
        >
          <template v-slot:label>
            Worm BM
            <sb-hint v-if="hints.template_worm_bm">{{
              hints.template_worm_bm
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.template_spam_bot"
          :loading="loading.template_spam_bot"
        >
          <template v-slot:label>
            Spam Bot
            <sb-hint v-if="hints.template_spam_bot">{{
              hints.template_spam_bot
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.template_chat_bot"
          :loading="loading.template_chat_bot"
        >
          <template v-slot:label>
            Chat Bot
            <sb-hint v-if="hints.template_chat_bot">{{
              hints.template_chat_bot
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.template_checkpoint"
          :loading="loading.template_checkpoint"
        >
          <template v-slot:label>
            Checkpoint
            <sb-hint v-if="hints.template_checkpoint">{{
              hints.template_checkpoint
            }}</sb-hint>
          </template>
        </v-switch>
      </v-card-text>
    </v-card>

    <v-card class="mt-10">
      <v-card-title>
        <v-icon large left>mdi-application</v-icon>
        <span class="title font-weight-light">{{
          $ml.get('general_server_options')
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-switch
          class="mt-0"
          v-model="fields.remove_invalid_accounts"
          :loading="loading.remove_invalid_accounts"
        >
          <template v-slot:label>
            {{ $ml.get('remove_invalid_accounts') }}
            <sb-hint v-if="hints.remove_invalid_accounts">{{
              hints.remove_invalid_accounts
            }}</sb-hint>
          </template>
        </v-switch>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        template_registrator: false,
        template_worm: false,
        template_worm_bm: false,
        template_spam_bot: false,
        template_chat_bot: false,
        template_checkpoint: false,
        remove_invalid_accounts: false
      },
      loading: {
        template_registrator: false,
        template_worm: false,
        template_worm_bm: false,
        template_spam_bot: false,
        template_chat_bot: false,
        template_checkpoint: false,
        remove_invalid_accounts: false
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios
      .post('setting/get', { code: 'templates-auto-control' })
      .then((response) => {
        this.fields = response.data.fields
        this.hints = response.data.hints
        this.startWatch()
      })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'templates-auto-control',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
