<template>
  <v-row justify="start" align="start">
    <v-col cols="12">
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ $ml.get('data') }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list>
        <v-list-item-group>
          <template v-for="(item, index) in items">
            <v-list-item
              :key="item.name"
              link
              :to="'/data/' + $route.params.section + '/' + item.url"
            >
              <v-list-item-icon>
                <sb-hint v-if="item.help_status && item.help != ''">{{
                  item.help
                }}</sb-hint>
                <v-btn
                  v-else
                  class="ml-2"
                  color="grey lighten-1"
                  small
                  icon
                  dark
                >
                  <v-icon>mdi-database-cog</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  class="ma-2"
                  small
                  :color="getColor(item.total)"
                  text-color="white"
                >
                  {{ item.total }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import SbHint from '@/components/Hint.vue'

export default {
  data() {
    return {
      items: {},
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  watch: {
    $route() {
      this.getDataFromApi()
    },
  },
  methods: {
    getDataFromApi() {
      let data = {
        type: this.$route.params.section,
      }

      this.axios.post('data/section', data).then((response) => {
        this.items = response.data
      })
    },
    getColor(total) {
      if (total != 0) {
        return 'green'
      } else {
        return 'red'
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
