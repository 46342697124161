<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-account-group</v-icon>
      <span class="title font-weight-light">{{ $ml.get('registration') }}</span>
      <v-spacer />
      <v-switch
        v-model="fields.reger_status"
        :loading="loading.reger_status"
        persistent-hint
      >
        <template v-slot:label>
          <sb-hint v-if="hints.reger_status">{{ hints.reger_status }}</sb-hint>
        </template>
      </v-switch>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-bind:class="{ 'turned-off': !fields.reger_status }">
        <v-slider
          v-model="fields.account_pause_after_registr"
          :min="1"
          :max="48"
          step="1"
          thumb-label="always"
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('wait') }}
            <sb-hint v-if="hints.account_pause_after_registr">{{
              hints.account_pause_after_registr
            }}</sb-hint>
          </template>
        </v-slider>

        <v-slider
          v-model="fields.reg_threads_count"
          :min="1"
          :max="99"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('streams') }}
            <sb-hint v-if="hints.reg_threads_count">{{
              hints.reg_threads_count
            }}</sb-hint>
          </template>
        </v-slider>

        <v-card-title class="pl-0">{{ $ml.get('registration') }}</v-card-title>

        <v-range-slider
          v-model="fields.reg_time_range"
          :min="0"
          :max="24"
          step="1"
          thumb-label="always"
          ticks
          :disabled="fields.reg_around_the_clock"
        >
          <template v-slot:label>
            {{ $ml.get('reg_time') }}
            <sb-hint v-if="hints.reg_time_range">{{
              hints.reg_time_range
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-switch
          class="mt-0"
          v-model="fields.reg_around_the_clock"
          :loading="loading.reg_around_the_clock"
        >
          <template v-slot:label>
            {{ $ml.get('reg_around_clock') }}
            <sb-hint v-if="hints.reg_around_the_clock">{{
              hints.reg_around_the_clock
            }}</sb-hint>
          </template>
        </v-switch>


        <div class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('reg_method') }}
              <sb-hint v-if="hints.reg_way">{{ hints.reg_way }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-select
              v-model="fields.reg_way"
              :items="reg_ways"
              :loading="loading.reg_way"
              item-text="state"
              item-value="abbr"
              single-line
            ></v-select>
          </div>
        </div>

        <v-switch
          class="mt-0"
          v-model="fields.relink_to_service_phone"
          :loading="loading.relink_to_service_phone"
        >
          <template v-slot:label>
            {{ $ml.get('rebind_service_number') }}
            <sb-hint v-if="hints.relink_to_service_phone">{{
              hints.relink_to_service_phone
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.relink_to_email"
          :loading="loading.relink_to_email"
        >
          <template v-slot:label>
            {{ $ml.get('rebind_to_mail') }}
            <sb-hint v-if="hints.relink_to_email">{{
              hints.relink_to_email
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.get_phone_on_reg_ban"
          :loading="loading.get_phone_on_reg_ban"
        >
          <template v-slot:label>
            {{ $ml.get('receive_phone_banned_reg') }}
            <sb-hint v-if="hints.get_phone_on_reg_ban">{{
              hints.get_phone_on_reg_ban
            }}</sb-hint>
          </template>
        </v-switch>

        <v-card-title class="pl-0 pb-0">Kopeechka.store</v-card-title>

        <v-switch
          v-model="fields.kopeechka_status"
          :loading="loading.kopeechka_status"
        >
          <template v-slot:label>
            {{ $ml.get('mail_from_service') }} kopeechka.store
            <sb-hint v-if="hints.kopeechka_status">{{
              hints.kopeechka_status
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.kopeechka_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              Api {{ $ml.get('key') }}
              <sb-hint v-if="hints.kopeechka_api_key">{{
                hints.kopeechka_api_key
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.kopeechka_api_key"
              :loading="loading.kopeechka_api_key"
            >
            </v-text-field>
          </div>
        </div>

        <v-switch
          class="mt-0"
          v-if="fields.kopeechka_status"
          v-model="fields.kopeechka_check_valid"
          :loading="loading.kopeechka_check_valid"
        >
          <template v-slot:label>
            {{ $ml.get('check_valid') }}
            <sb-hint v-if="hints.kopeechka_check_valid">{{
              hints.kopeechka_check_valid
            }}</sb-hint>
          </template>
        </v-switch>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('filling') }}</v-card-title>

        <v-switch
          v-model="fields.brides_bay_photo"
          :loading="loading.brides_bay_photo"
        >
          <template v-slot:label>
            {{ $ml.get('photo_from') }} BridesBay
            <sb-hint v-if="hints.brides_bay_photo">{{
              hints.brides_bay_photo
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          v-model="fields.fill_info"
          :loading="loading.fill_info"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('filling_inf') }}
            <sb-hint v-if="hints.fill_info">{{ hints.fill_info }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          v-if="fields.fill_info"
          v-model="fields.fill_by_current_ip"
          :loading="loading.fill_by_current_ip"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('fill_based_ip') }}
            <sb-hint v-if="hints.fill_by_current_ip">{{
              hints.fill_by_current_ip
            }}</sb-hint>
          </template>
        </v-switch>

        <div
          class="d-flex align-center"
        >
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('education') }}
              <sb-hint v-if="hints.text_to_education">{{
                hints.text_to_education
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-textarea filled v-model="fields.text_to_education"> </v-textarea>
          </div>
        </div>

        <div
          class="d-flex align-center"
        >
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('work') }}
              <sb-hint v-if="hints.text_to_work">{{
                hints.text_to_work
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-textarea filled v-model="fields.text_to_work"> </v-textarea>
          </div>
        </div>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('posts') }}</v-card-title>

        <v-range-slider
          v-model="fields.post_count"
          :min="0"
          :max="10"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('posts_num_at_reg') }}
            <sb-hint v-if="hints.post_count">{{ hints.post_count }}</sb-hint>
          </template>
        </v-range-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('friends') }}</v-card-title>

        <v-switch
          v-model="fields.add_friends_status"
          :loading="loading.add_friends_status"
        >
          <template v-slot:label>
            {{ $ml.get('friends_when_reg') }}
            <sb-hint v-if="hints.add_friends_status">{{
              hints.add_friends_status
            }}</sb-hint>
          </template>
        </v-switch>

        <v-range-slider
          v-if="fields.add_friends_status"
          v-model="fields.add_friends_count"
          :min="1"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('friends_number') }}
            <sb-hint v-if="hints.add_friends_count">{{
              hints.add_friends_count
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('sites') }}</v-card-title>

        <v-range-slider
          v-model="fields.visit_sites"
          :min="0"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('sites_before_reg') }}
            <sb-hint v-if="hints.visit_sites">{{ hints.visit_sites }}</sb-hint>
          </template>
        </v-range-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('gender_selection') }}</v-card-title>

        <v-switch
          v-model="fields.gender_choose"
          :loading="loading.gender_choose"
        >
          <template v-slot:label>
            {{ $ml.get('gender_selection') }}
            <sb-hint v-if="hints.gender_choose">{{
              hints.gender_choose
            }}</sb-hint>
          </template>
        </v-switch>

        <v-radio-group
          v-if="fields.gender_choose"
          v-model="fields.gender_type"
          row
        >
          <v-radio :label="$ml.get('male')" value="male"></v-radio>
          <v-radio :label="$ml.get('female')" value="female"></v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      reg_ways: [
        { state: 'На мобильные ЮА на телефон', abbr: 'by_mobile_ua_phone' },
        {
          state: 'Через оффициальную прилу на запросах',
          abbr: 'by_official_app_queries',
        },
        // { state: 'На почту', abbr: 'by_email' },
        { state: 'Регистрация на десктопную версию Facebook', abbr: 'by_phone' },
        { state: 'Оф прилу + веб', abbr: 'by_app_web' },
        // { state: 'На телефон', abbr: 'by_phone' },
        // { state: 'На мобильные ЮА через почту', abbr: 'by_mobile_ua_email' },
        // { state: 'На свои номера', abbr: 'by_personal_phones' },
      ],
      fields: {
        reger_status: false,
        account_pause_after_registr: 2,
        reg_threads_count: 0,
        reg_way: '',
        relink_to_service_phone: false,
        relink_to_email: false,
        get_phone_on_reg_ban: false,
        kopeechka_status: false,
        kopeechka_api_key: '',
        kopeechka_check_valid: false,
        brides_bay_photo: false,
        fill_info: true,
        fill_by_current_ip: false,
        text_to_education: '',
        text_to_work: '',
        post_count: [0, 0],
        add_friends_status: false,
        add_friends_count: [5, 10],
        visit_sites: [1, 10],
        gender_choose: false,
        gender_type: 'female',
        reg_time_range: [6, 24],
        reg_around_the_clock: false,
      },
      loading: {
        reger_status: false,
        account_pause_after_registr: false,
        reg_way: false,
        relink_to_service_phone: false,
        relink_to_email: false,
        get_phone_on_reg_ban: false,
        kopeechka_status: false,
        kopeechka_api_key: false,
        kopeechka_check_valid: false,
        brides_bay_photo: false,
        fill_info: false,
        fill_by_current_ip: false,
        text_to_education: false,
        text_to_work: false,
        post_count: false,
        add_friends_status: false,
        add_friends_count: false,
        visit_sites: false,
        gender_choose: false,
        gender_type: false,
        reg_time_range: false,
        reg_around_the_clock: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 300)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'accounts' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'accounts',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>

<style>
.turned-off {
  opacity: 0.39;
}
</style>
