<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-briefcase-download</v-icon>
      <span class="title font-weight-light">{{ $ml.get('autoupload') }}</span>
    </v-card-title>

    <v-card-text>
      <div
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('cardslist') }}
            <sb-hint v-if="hints.cards_list">{{
              hints.cards_list
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea
            filled
            v-model="fields.cards_list"
            :label="$ml.get('cardslist')"
            placeholder="1234123412341234|12/28|444
  1234123412341234|12/28|444
  1234123412341234|12/28|444"
            outlined
            dense
          > </v-textarea>
        </div>
      </div>

      <div
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('company') }}
            <sb-hint v-if="hints.company_for_import">{{
              hints.company_for_import
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea
            filled
            v-model="fields.company_for_import"
            :label="$ml.get('company')"
            :placeholder="$ml.get('enter_company')"
            outlined
            dense
          > </v-textarea>
        </div>
      </div>

      <div class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            <sb-hint v-if="hints.creative_folder_path">{{
              hints.creative_folder_path
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-text-field
            v-model="fields.creative_folder_path"
            :loading="loading.creative_folder_path"
            :label="$ml.get('creative_folder_path')"
            :placeholder="$ml.get('creative_folder_path')"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        creative_folder_path: '',
        cards_list: '',
        company_for_import: '',
      },
      loading: {
        creative_folder_path: false,
        cards_list: false,
        company_for_import: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 300)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'autoupload' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'autoupload',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          console.log('ok')
          vm.loading[data.key] = false
        })
        .catch(function () {
          console.log('not ok')
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>

