<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-account-multiple-plus</v-icon>
      <span class="title font-weight-light">{{ $ml.get('inviter_setting') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch
        v-model="fields.inviter_public"
        :loading="loading.inviter_public"
      >
        <template v-slot:label>
          {{ $ml.get('public_inviter') }}
          <sb-hint v-if="hints.inviter_public">{{
            hints.inviter_public
          }}</sb-hint>
        </template>
      </v-switch>

      <div v-if="fields.inviter_public" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('public_link') }}
            <sb-hint v-if="hints.inviter_public_link">{{
              hints.inviter_public_link
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-text-field
            v-model="fields.inviter_public_link"
            :loading="loading.inviter_public_link"
          >
          </v-text-field>
        </div>
      </div>

      <div v-if="fields.inviter_public" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('public_inviter_text') }}
            <sb-hint v-if="hints.inviter_public_invite_text">{{
              hints.inviter_public_invite_text
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.inviter_public_invite_text">
          </v-textarea>
        </div>
      </div>

      <div v-if="fields.inviter_public" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('group_links') }}
            <sb-hint v-if="hints.inviter_repost_links">{{
              hints.inviter_repost_links
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.inviter_repost_links">
          </v-textarea>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        inviter_public: '',
        inviter_public_link: '',
        inviter_public_invite_text: '',
        inviter_repost_links: '',
      },
      loading: {
        inviter_public: false,
        inviter_public_link: false,
        inviter_public_invite_text: false,
        inviter_repost_links: false,
      },
      hints: {}
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios
      .post('setting/get', { code: 'inviter_public' })
      .then((response) => {
        this.fields = response.data.fields
        this.hints = response.data.hints
        this.startWatch()
      })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'inviter_public',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
