<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-robot</v-icon>
      <span class="title font-weight-light">{{ $ml.get('farm_settings') }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-bind:class="{ 'turned-off': !fields.farm_status }">
        <v-switch
          class="mt-0"
          v-model="fields.remove_phone"
          :loading="loading.remove_phone"
        >
          <template v-slot:label>
            {{ $ml.get('del_phone') }}
            <sb-hint v-if="hints.remove_phone">{{
              hints.remove_phone
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.worm_status"
          :loading="loading.worm_status"
        >
          <template v-slot:label>
            Warm
            <sb-hint v-if="hints.worm_status">{{ hints.worm_status }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-show="fields.worm_status"
          v-model="fields.worm_threads_count"
          :min="1"
          :max="99"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('streams') }} Warm
            <sb-hint v-if="hints.worm_threads_count">{{
              hints.worm_threads_count
            }}</sb-hint>
          </template>
        </v-slider>

        <v-switch
          class="mt-0"
          v-model="fields.turn_off_profile"
          :loading="loading.turn_off_profile"
        >
          <template v-slot:label>
            {{ $ml.get('disable') }} {{ $ml.get('account') }}
            <sb-hint v-if="hints.turn_off_profile">{{
              hints.turn_off_profile
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-show="fields.turn_off_profile"
          v-model="fields.turn_off_profile_after_days"
          :min="1"
          :max="50"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('dis_acc') }}
            <sb-hint v-if="hints.turn_off_profile_after_days">{{
              hints.turn_off_profile_after_days
            }}</sb-hint>
          </template>
        </v-slider>

        <v-switch
          class="mt-0"
          v-model="fields.checkpoint_status"
          :loading="loading.checkpoint_status"
        >
          <template v-slot:label>
            {{ $ml.get('checkpoint') }}
            <sb-hint v-if="hints.checkpoint_status">{{
              hints.checkpoint_status
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-show="fields.checkpoint_status"
          v-model="fields.checkpoint_threads_count"
          :min="1"
          :max="99"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('streams') }} {{ $ml.get('checkpoint') }}
            <sb-hint v-if="hints.checkpoint_threads_count">{{
              hints.checkpoint_threads_count
            }}</sb-hint>
          </template>
        </v-slider>

        <v-switch
          class="mt-0"
          v-model="fields.change_proxy_on_invalid"
          :loading="loading.change_proxy_on_invalid"
        >
          <template v-slot:label>
            {{ $ml.get('change_proxy_inv') }} (Warm)
            <sb-hint v-if="hints.change_proxy_on_invalid">{{
              hints.change_proxy_on_invalid
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          class="mt-0"
          v-model="fields.change_proxy_on_invalid_checkpoint"
          :loading="loading.change_proxy_on_invalid_checkpoint"
        >
          <template v-slot:label>
            {{ $ml.get('change_proxy_inv') }} (Checkpoint)
            <sb-hint v-if="hints.change_proxy_on_invalid_checkpoint">{{
              hints.change_proxy_on_invalid_checkpoint
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          v-model="fields.accept_friends_on_farm"
          :loading="loading.accept_friends_on_farm"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('appr_friends') }}
            <sb-hint v-if="hints.accept_friends_on_farm">{{
              hints.accept_friends_on_farm
            }}</sb-hint>
          </template>
        </v-switch>

        <v-range-slider
          v-if="fields.accept_friends_on_farm"
          v-model="fields.accept_friends_count"
          :min="1"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('count_appr_friends') }}
            <sb-hint v-if="hints.accept_friends_count">{{
              hints.accept_friends_count
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-range-slider
          v-model="fields.action_interval"
          :min="1"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('interval_betw_act') }}
            <sb-hint v-if="hints.action_interval">{{
              hints.action_interval
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-range-slider
          v-model="fields.often_to_run"
          :min="1"
          :max="50"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('launch_accounts') }} checkpoint?
            <sb-hint v-if="hints.often_to_run">{{
              hints.often_to_run
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-range-slider
          v-model="fields.often_to_run_worm"
          :min="1"
          :max="50"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('launch_accounts') }} warm?
            <sb-hint v-if="hints.often_to_run_worm">{{
              hints.often_to_run_worm
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-switch
          class="mt-0"
          v-model="fields.emulate_mouse"
          :loading="loading.emulate_mouse"
        >
          <template v-slot:label>
            {{ $ml.get('emulate_mouse') }}
            <sb-hint v-if="hints.emulate_mouse">{{
              hints.emulate_mouse
            }}</sb-hint>
          </template>
        </v-switch>

        <v-card-title class="pl-0 pb-0">Fan Page</v-card-title>

        <v-switch v-model="fields.fan_page" :loading="loading.fan_page">
          <template v-slot:label>
            {{ $ml.get('create') }} Fan Page
            <sb-hint v-if="hints.fan_page">{{ hints.fan_page }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.fan_page" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('names') }} Fan Pages
              <sb-hint v-if="hints.fan_page_names">{{
                hints.fan_page_names
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-textarea filled v-model="fields.fan_page_names"> </v-textarea>
          </div>
        </div>

        <v-slider
          v-if="fields.fan_page"
          v-model="fields.day_to_create_fan_page"
          :min="0"
          :max="20"
          step="1"
          thumb-label="always"
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('create_date') }}
            <sb-hint v-if="hints.day_to_create_fan_page">{{
              hints.day_to_create_fan_page
            }}</sb-hint>
          </template>
        </v-slider>

        <v-switch
          v-if="fields.fan_page"
          v-model="fields.fan_page_fill_from_lifehacker"
          :loading="loading.fan_page_fill_from_lifehacker"
        >
          <template v-slot:label>
            {{ $ml.get('fill_posts') }} LifeHacker
            <sb-hint v-if="hints.fan_page_fill_from_lifehacker">{{
              hints.fan_page_fill_from_lifehacker
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-if="fields.fan_page_fill_from_lifehacker && fields.fan_page"
          v-model="fields.fan_page_lifehacker_postcount"
          :min="0"
          :max="30"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('posts_count') }}
            <sb-hint v-if="hints.fan_page_lifehacker_postcount">{{
              hints.fan_page_lifehacker_postcount
            }}</sb-hint>
          </template>
        </v-slider>

        <div class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('how_solve_captcha') }}
              <sb-hint v-if="hints.capthca_type">{{
                hints.capthca_type
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-select
              class="mt-0"
              v-model="fields.capthca_type"
              :items="capthca_types"
              :loading="loading.capthca_type"
              item-text="state"
              item-value="abbr"
            >
            </v-select>
          </div>
        </div>

        <v-switch
          class="mt-0"
          v-model="fields.post_from_sites"
          :loading="loading.post_from_sites"
          :label="$ml.get('sites_posts') + '(Google Trneds, Fishki, Pikabu)'"
        >
          <template v-slot:label>
            {{ $ml.get('sites_posts') }} (Google Trneds, Fishki, Pikabu)
            <sb-hint v-if="hints.post_from_sites">{{
              hints.post_from_sites
            }}</sb-hint>
          </template>
        </v-switch>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('walking_cookies') }}</v-card-title>

        <v-switch
          v-model="fields.visit_sites_status"
          :loading="loading.visit_sites_status"
        >
          <template v-slot:label>
            {{ $ml.get('browse_sites') }}
            <sb-hint v-if="hints.visit_sites_status">{{
              hints.visit_sites_status
            }}</sb-hint>
          </template>
        </v-switch>

        <v-range-slider
          v-if="fields.visit_sites_status"
          v-model="fields.visited_sites_count"
          :min="0"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('count_browse_sites') }}
            <sb-hint v-if="hints.visited_sites_count">{{
              hints.visited_sites_count
            }}</sb-hint>
          </template>
        </v-range-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('additionally') }}</v-card-title>

        <v-switch
          v-model="fields.relink_to_desktop_ua"
          :loading="loading.relink_to_desktop_ua"
        >
          <template v-slot:label>
            {{ $ml.get('rebind_desktop_ua') }}
            <sb-hint v-if="hints.relink_to_desktop_ua">{{
              hints.relink_to_desktop_ua
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-if="fields.relink_to_desktop_ua"
          v-model="fields.relink_to_desktop_ua_day_start"
          :min="0"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('rebinding_day') }}
            <sb-hint v-if="hints.relink_to_desktop_ua_day_start">{{
              hints.relink_to_desktop_ua_day_start
            }}</sb-hint>
          </template>
        </v-slider>

        <div class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('accs_to_friend') }}
              <sb-hint v-if="hints.links_to_invite">{{
                hints.links_to_invite
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-textarea v-model="fields.links_to_invite" filled></v-textarea>
          </div>
        </div>

        <v-switch
          v-model="fields.ads_refriend_friends"
          :loading="loading.ads_refriend_friends"
        >
          <template v-slot:label>
            {{ $ml.get('renew_friends') }}
            <sb-hint v-if="hints.ads_refriend_friends">{{
              hints.ads_refriend_friends
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          class="mt-6"
          v-model="fields.ads_invite_friends_day_start"
          :min="0"
          :max="30"
          persistent-hint
          step="1"
          thumb-label="always"
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('friendship_day') }}
            <sb-hint v-if="hints.ads_invite_friends_day_start">{{
              hints.ads_invite_friends_day_start
            }}</sb-hint>
          </template>
        </v-slider>

        <v-slider
          class="mt-6"
          v-model="fields.ads_friends_count"
          :min="0"
          :max="2000"
          persistent-hint
          step="50"
          thumb-label="always"
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('friends_number') }}
            <sb-hint v-if="hints.ads_friends_count">{{
              hints.ads_friends_count
            }}</sb-hint>
          </template>
        </v-slider>

        <v-range-slider
          v-model="fields.fakes_count"
          :min="0"
          :max="48"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('fakes_number') }}
            <sb-hint v-if="hints.fakes_count">{{ hints.fakes_count }}</sb-hint>
          </template>
        </v-range-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('login_to_sites') }}</v-card-title>

        <v-switch
          v-model="fields.login_to_sites"
          :loading="loading.login_to_sites"
        >
          <template v-slot:label>
            {{ $ml.get('login_to_sites') }}
            <sb-hint v-if="hints.login_to_sites">{{
              hints.login_to_sites
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.login_to_sites" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('authorization_sites') }}
              <sb-hint v-if="hints.lts_sites">{{ hints.lts_sites }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-textarea v-model="fields.lts_sites" filled></v-textarea>
          </div>
        </div>

        <v-slider
          v-show="fields.login_to_sites"
          class="mt-6"
          v-model="fields.lts_start_day"
          :min="0"
          :max="30"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('start_day') }}
            <sb-hint v-if="hints.lts_start_day">{{
              hints.lts_start_day
            }}</sb-hint>
          </template>
        </v-slider>

        <v-slider
          v-show="fields.login_to_sites"
          class="mt-6"
          v-model="fields.lts_count"
          :min="0"
          :max="30"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('logins_number') }}
            <sb-hint v-if="hints.lts_count">{{ hints.lts_count }}</sb-hint>
          </template>
        </v-slider>

        <v-card-title class="pl-0 pb-0">{{ $ml.get('spam_bot') }}</v-card-title>

        <v-switch
          v-model="fields.spam_bot_enable"
          :loading="loading.spam_bot_enable"
        >
          <template v-slot:label>
            {{ $ml.get('enable_spam_bot') }}
            <sb-hint v-if="hints.spam_bot_enable">{{
              hints.spam_bot_enable
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-model="fields.spam_bot_treads"
          :min="1"
          :max="99"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('threads_number') }}
            <sb-hint v-if="hints.spam_bot_treads">{{
              hints.spam_bot_treads
            }}</sb-hint>
          </template>
        </v-slider>

        <v-slider
          v-model="fields.spam_bot_how_often_run"
          :min="1"
          :max="99"
          persistent-hint
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('spam_bot_runs') }}
            <sb-hint v-if="hints.spam_bot_how_often_run">{{
              hints.spam_bot_how_often_run
            }}</sb-hint>
          </template>
        </v-slider>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      capthca_types: [
        { state: 'CapMonsterCloud.dll', abbr: 'CapMonsterCloud.dll' },
        { state: 'Anti-Captcha.dll', abbr: 'Anti-Captcha.dll' },
        { state: 'CapMonster2.dll', abbr: 'CapMonster2.dll' },
        { state: 'RuCaptcha.dll', abbr: 'RuCaptcha.dll' },
      ],
      fields: {
        farm_status: '',
        random_script: '',
        mini_farm: '',
        worm_status: false,
        worm_threads_count: 0,
        turn_off_profile: false,
        turn_off_profile_after_days: 0,
        checkpoint_status: false,
        checkpoint_threads_count: 0,
        accept_friends_on_farm: '',
        accept_friends_count: [5, 10],
        action_interval: [1, 5],
        often_to_run: [15, 50],
        often_to_run_worm: [15, 50],
        invite_man: '',
        invite_woman: '',
        emulate_mouse: '',
        fan_page: '',
        fan_page_names: '',
        day_to_create_fan_page: 3,
        fan_page_fill_from_lifehacker: false,
        fan_page_lifehacker_postcount: 0,
        create_bm: '',
        change_proxy_on_invalid: false,
        change_proxy_on_invalid_checkpoint: false,
        day_to_create_bm: 3,
        capthca_type: '',
        remove_phone: false,
        post_from_sites: false,
        fakes_count: [1, 10],
        visit_sites_status: false,
        visited_sites_count: [1, 10],
        links_to_invite: '',
        ads_refriend_friends: false,
        ads_invite_friends_day_start: 0,
        ads_friends_count: 0,
        relink_to_desktop_ua: false,
        relink_to_desktop_ua_day_start: 1,
        login_to_sites: false,
        lts_sites: '',
        lts_start_day: 0,
        lts_count: 0,
        spam_bot_enable: false,
        spam_bot_treads: 3,
        spam_bot_how_often_run: 15,
      },
      loading: {
        farm_status: false,
        random_script: false,
        mini_farm: false,
        worm_status: false,
        worm_threads_count: false,
        turn_off_profile: false,
        turn_off_profile_after_days: false,
        checkpoint_status: false,
        checkpoint_threads_count: false,
        accept_friends_on_farm: false,
        accept_friends_count: false,
        action_interval: false,
        often_to_run: false,
        often_to_run_worm: false,
        invite_man: false,
        invite_woman: false,
        emulate_mouse: false,
        fan_page: false,
        fan_page_names: false,
        day_to_create_fan_page: false,
        fan_page_fill_from_lifehacker: false,
        create_bm: false,
        change_proxy_on_invalid: false,
        change_proxy_on_invalid_checkpoint: false,
        day_to_create_bm: false,
        capthca_type: false,
        remove_phone: false,
        post_from_sites: false,
        fakes_count: false,
        visit_sites_status: false,
        visited_sites_count: false,
        links_to_invite: false,
        ads_refriend_friends: false,
        ads_invite_friends_day_start: false,
        ads_friends_count: false,
        relink_to_desktop_ua: false,
        relink_to_desktop_ua_day_start: false,
        login_to_sites: false,
        lts_sites: false,
        lts_start_day: false,
        lts_count: false,
        spam_bot_enable: false,
        spam_bot_treads: false,
        spam_bot_how_often_run: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'general' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'general',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>

<style>
.turned-off {
  opacity: 0.39;
}
</style>
