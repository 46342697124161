import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import './lang/ml'
import {i18n} from './lang/i18n'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import store from './store'
import router from './router'
import './registerServiceWorker'

Vue.config.productionTip = false
axios.defaults.baseURL = '/api/'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
// axios.defaults.baseURL = 'https://fb.socrobotic.anedod.ru/api/'

Vue.use(VueAxios, axios)
Vue.use(VueRouter)

const options = {
  position: "top-right",
  timeout: 7000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  // icon: "fas fa-rocket",
  rtl: false
};

Vue.use(Toast, options);

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(
  response => { return response; },
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  }
)

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    store.dispatch("removeToken").then(() => {
      router.push("/")
    })
  }

  Vue.$toast.error(error.response.data.message)

  return Promise.reject(error)
})


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
