<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-newspaper</v-icon>
      <span class="title font-weight-light">{{ $ml.get('posting_setup') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch
        class="mt-0"
        v-model="fields.posting"
        :loading="loading.posting"
      >
        <template v-slot:label>
          {{ $ml.get('posting') }}
          <sb-hint v-if="hints.posting">{{ hints.posting }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        v-show="fields.posting"
        v-model="fields.frends_for_posting_start"
        :min="100"
        :max="1000"
        persistent-hint
        step="100"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('friends_number_to_start') }}
          <sb-hint v-if="hints.frends_for_posting_start">{{
            hints.frends_for_posting_start
          }}</sb-hint>
        </template>
      </v-slider>

      <div v-if="fields.posting" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('wall_posts_text') }}
            <sb-hint v-if="hints.text_to_posting">{{
              hints.text_to_posting
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.text_to_posting"> </v-textarea>
        </div>
      </div>

      <div v-if="fields.posting" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            RSS
            <sb-hint v-if="hints.rss_links">{{ hints.rss_links }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.rss_links"> </v-textarea>
        </div>
      </div>

      <v-switch
        class="mt-0"
        v-model="fields.post_link_and_text_to_profile"
        :loading="loading.post_link_and_text_to_profile"
      >
        <template v-slot:label>
          {{ $ml.get('post_link_text') }}
          <sb-hint v-if="hints.post_link_and_text_to_profile">{{
            hints.post_link_and_text_to_profile
          }}</sb-hint>
        </template>
      </v-switch>

      <div
        v-if="fields.post_link_and_text_to_profile"
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('biography_text') }}
            <sb-hint v-if="hints.text_to_bio">{{ hints.text_to_bio }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.text_to_bio"> </v-textarea>
        </div>
      </div>

      <v-slider
        class="mt-6"
        v-model="fields.reposting_day_start"
        :min="0"
        :max="30"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('start_day') }}
          <sb-hint v-if="hints.reposting_day_start">{{
            hints.reposting_day_start
          }}</sb-hint>
        </template>
      </v-slider>

      <div class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('links_groups_publics') }}
            <sb-hint v-if="hints.links_to_repost">{{
              hints.links_to_repost
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.links_to_repost"> </v-textarea>
        </div>
      </div>

      <v-switch
        class="mt-0"
        v-model="fields.posting_like"
        :loading="loading.posting_like"
      >
        <template v-slot:label>
          {{ $ml.get('like_posts') }}
          <sb-hint v-if="hints.posting_like">{{ hints.posting_like }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        class="mt-0"
        v-model="fields.posting_share"
        :loading="loading.posting_share"
      >
        <template v-slot:label>
          {{ $ml.get('share_posts') }}
          <sb-hint v-if="hints.posting_share">{{
            hints.posting_share
          }}</sb-hint>
        </template>
      </v-switch>

      <v-card-title class="pl-0 pb-0">{{ $ml.get('section') }}</v-card-title>

      <v-switch v-model="fields.like_to_post" :loading="loading.like_to_post">
        <template v-slot:label>
          {{ $ml.get('like_post') }}
          <sb-hint v-if="hints.like_to_post">{{ hints.like_to_post }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        class="mt-0"
        v-model="fields.comment_to_post_status"
        :loading="loading.comment_to_post_status"
      >
        <template v-slot:label>
          {{ $ml.get('comment_post') }}
          <sb-hint v-if="hints.comment_to_post_status">{{
            hints.comment_to_post_status
          }}</sb-hint>
        </template>
      </v-switch>

      <div v-if="fields.comment_to_post_status" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('comment_text') }}
            <sb-hint v-if="hints.comment_to_post">{{
              hints.comment_to_post
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.comment_to_post"> </v-textarea>
        </div>
      </div>

      <v-switch
        class="mt-0"
        v-model="fields.post_to_dating_group"
        :loading="loading.post_to_dating_group"
      >
        <template v-slot:label>
          {{ $ml.get('dating_groups_post') }}
          <sb-hint v-if="hints.post_to_dating_group">{{
            hints.post_to_dating_group
          }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        class="mt-6"
        v-show="fields.post_to_dating_group"
        v-model="fields.post_to_dating_group_day_start"
        :min="0"
        :max="30"
        hint="На какой день вступать в эти группы"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('start_day') }}
          <sb-hint v-if="hints.post_to_dating_group_day_start">{{
            hints.post_to_dating_group_day_start
          }}</sb-hint>
        </template>
      </v-slider>

      <div
        class="d-flex align-center"
      >
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('groups_of_group_field') }}
            <sb-hint v-if="hints.text_to_bio">{{ hints.groups_of_group_field }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.groups_of_group_field"> </v-textarea>
        </div>
      </div>

      <div class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('text_input_field') }}
            <sb-hint v-if="hints.text_input_field">{{
              hints.text_input_field
            }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.text_input_field"> </v-textarea>
        </div>
      </div>

      <v-text-field
        v-model="fields.pictures_folder_path"
        :loading="loading.pictures_folder_path"
        :label="$ml.get('pictures_folder_path')"
        :placeholder="$ml.get('pictures_folder_path')"
        outlined
        dense
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        posting: '',
        frends_for_posting_start: 300,
        text_to_posting: '',
        rss_links: '',
        text_to_bio: '',
        groups_of_group_field: '',
        text_input_field: '',
        pictures_folder_path: '',
        post_link_and_text_to_profile: '',
        reposting_day_start: 0,
        links_to_repost: '',
        posting_like: false,
        posting_share: false,
        like_to_post: false,
        comment_to_post_status: false,
        comment_to_post: '',
        post_to_dating_group: false,
        post_to_dating_group_day_start: 7,
      },
      loading: {
        posting: false,
        frends_for_posting_start: false,
        text_to_posting: false,
        rss_links: false,
        text_to_bio: false,
        text_input_field: false,
        groups_of_group_field: false,
        post_link_and_text_to_profile: false,
        reposting_day_start: false,
        links_to_repost: false,
        posting_like: false,
        posting_share: false,
        like_to_post: false,
        comment_to_post_status: false,
        comment_to_post: false,
        post_to_dating_group: false,
        post_to_dating_group_day_start: false,
        pictures_folder_path: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'posting' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'posting',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
