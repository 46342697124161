<template>
  <v-row justify="start" align="start">
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :loading="tableLoading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :server-items-length="totalUsers"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $ml.get('proxies') }} ({{ totalUsers }})</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-select
              style="max-width: 200px"
              class="mt-6 mr-6"
              v-model="proxy_type"
              :items="proxy_types"
              item-text="state"
              item-value="abbr"
              :label="$ml.get('proxies_unload')"
            ></v-select>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="downloadFile"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('download') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="red"
                  @click="resetTable"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('delete_all_entries') }}</span>
            </v-tooltip>
          </v-toolbar>

          <v-card flat color="primary" dark>
            <v-card-actions dense @click="show = !show">
              <v-btn color="dark lighten-2" x-small text> {{ $ml.get('proxy_import') }} </v-btn>

              <v-spacer></v-spacer>

              <v-btn x-small icon>
                <v-icon>{{
                  show ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                  <v-form v-on:submit.prevent="proxyUpload">
                    <p>{{ $ml.get('proxy_formats') }}</p>
                    <p>{{ $ml.get('link_for_reconnect_notification') }}</p>
                    <p>
                      login:pass@ip:port<br />
                      ip:port
                    </p>
                    <p>{{ $ml.get('proxy_socks5') }}
                      <pre>socks5://login:pass@ip:port</pre>
                      <pre>socks5://ip:port</pre>
                    </p>

                    <v-file-input
                      v-model="upload.proxy_file"
                      accept=".txt"
                      :label="$ml.get('proxy_txt')"
                    ></v-file-input>

                    <v-btn
                      color="primary"
                      type="submit"
                      :disabled="upload.proxy_file == null"
                      :loading="loadingSubmit"
                      >{{ $ml.get('upload') }}</v-btn
                    >
                  </v-form>
                </v-card-text>
                <v-progress-linear
                  v-if="progress > 0"
                  :value="progress"
                ></v-progress-linear>
              </div>
            </v-expand-transition>
          </v-card>

          <v-slider
            v-model="fields.proxy_max_threads"
            :min="1"
            :max="20"
            step="1"
            thumb-label
            ticks
            class="mt-6"
          >
            <template v-slot:label>
              {{ $ml.get('one_proxy_streams') }}
              <sb-hint v-if="hints.proxy_max_threads">{{
                hints.proxy_max_threads
              }}</sb-hint>
            </template>
          </v-slider>
        </template>

        <template v-slot:[`item.valid`]="{ item }">
          <v-chip :color="getColor(item.valid)" dark>{{
            getStatusName(item.valid)
          }}</v-chip>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-col>
    <v-col cols="12">
      <v-snackbar v-model="snackbar.status" :timeout="snackbar.timeout">
        {{ $ml.get('total_lines') }}: {{ snackbar.rows }}
        <br />

        {{ $ml.get('total_proxy') }}: {{ snackbar.proxies }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="snackbar.status = false"
            >{{ $ml.get('close') }}</v-btn
          >
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      proxy_type: '',
      dialog: false,
      totalUsers: 0,
      users: [],
      tableLoading: true,
      options: {},
      snackbar: {
        status: false,
        text: '',
        color: 'green',
      },
      page: 1,
      pageCount: 1,
      itemsPerPage: 5,
      show: false,
      upload: {
        proxy_format: '',
        proxy_file: null,
      },
      loadingSubmit: false,
      progress: 0,
      fields: {
        proxy_max_threads: 0,
      },
      loading: {
        proxy_max_threads: false,
      },
      hints: {},
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add') : this.$t('edit')
    },
    proxy_types() {
      return [
        { state: this.$t('all'), abbr: 'all' },
        { state: this.$t('valid'), abbr: 'valid' },
      ]
    },
    headers() {
      return [
        { text: '#', align: 'start', value: 'id' },
        { text: this.$t('proxy'), align: 'start', value: 'proxy' },
        { text: this.$t('status'), align: 'end', value: 'valid' },
      ]
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 300)
  },
  mounted() {
    this.getDataFromApi()
    this.axios.post('setting/get', { code: 'proxy' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    page() {
      this.getDataFromApi()
    },
  },
  methods: {
    getDataFromApi() {
      this.tableLoading = true

      let data = {
        page: this.page,
      }

      if (this.options.sortBy != 0) {
        data.sort = this.options.sortBy[0]

        if (this.options.sortDesc[0]) {
          data.sort_order = 'desc'
        } else {
          data.sort_order = 'asc'
        }
        console.log(this.options)
      }

      this.axios.post('data/proxy/list', data).then((response) => {
        this.users = response.data.data
        this.totalUsers = response.data.total
        this.pageCount = response.data.total_page
        this.tableLoading = false
      })
    },
    getColor(status) {
      if (status == '1') {
        return 'green'
      } else {
        return 'red'
      }
    },
    getStatusName(status) {
      if (status == '1') {
        return this.$t('valid')
      } else {
        return this.$t('not_valid')
      }
    },
    resetTable() {
      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
      }

      this.axios.post('proxy/remove-all', data).then(() => {
        this.getDataFromApi()
      })
    },
    downloadFile() {
      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
      }

      if (this.proxy_type != '') {
        data.valid = this.proxy_type
      }

      this.axios
        .post('data/proxy/download', data, {
          responseType: 'blob',
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'proxies.csv')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    proxyUpload() {
      this.loadingSubmit = true
      let formData = new FormData()

      formData.append('format', this.upload.proxy_format)
      formData.append('file', this.upload.proxy_file)

      this.axios
        .post('proxy/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: function (progressEvent) {
            this.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            )
          }.bind(this),
        })
        .then((response) => {
          this.progress = 0
          this.loadingSubmit = false
          this.upload = {}
          this.snackbar.status = true
          this.snackbar.rows = response.data.rows
          this.snackbar.proxies = response.data.proxies
          this.getDataFromApi()
        })
        .catch(() => {
          this.progress = 0
          this.loadingSubmit = false
        })
    },
    saveThis(field, value) {
      let data = {
        code: 'proxy',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
