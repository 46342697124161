import Vue from 'vue'
import VueRouter from 'vue-router'

import settingsRoutes from '@/router/settings.js'
import datasRoutes from '@/router/data.js'

import homePage from '@/views/Auth/Login'
import dashboard from '@/views/Dashboard'
import facebook from '@/views/Facebook'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homePage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facebook',
    name: 'Facebook',
    component: facebook,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(
    datasRoutes,
    settingsRoutes,
  )
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router
