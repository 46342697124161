<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-telegram</v-icon>
      <span class="title font-weight-light">Telegram</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-switch
          v-model="fields.logs_to_db_status"
          :loading="loading.logs_to_db_status"
        >
          <template v-slot:label>
            {{ $ml.get('logs_to_db') }}
            <sb-hint v-if="hints.logs_to_db_status">{{
              hints.logs_to_db_status
            }}</sb-hint>
          </template>
        </v-switch>
        <v-switch
          v-model="fields.logs_to_telegram_status"
          :loading="loading.logs_to_telegram_status"
        >
          <template v-slot:label>
            {{ $ml.get('logs_to_telegram') }}
            <sb-hint v-if="hints.logs_to_telegram_status">{{
              hints.logs_to_telegram_status
            }}</sb-hint>
          </template>
        </v-switch>
        <v-switch
          v-model="fields.telegram_reger_status"
          :loading="loading.telegram_reger_status"
        >
          <template v-slot:label>
            {{ $ml.get('notifications_for_reg') }}
            <sb-hint v-if="hints.telegram_reger_status">{{
              hints.telegram_reger_status
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.telegram_reger_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('token') }}
              <sb-hint v-if="hints.tg_reger_api_token">{{
                hints.tg_reger_api_token
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_reger_api_token"
              :loading="loading.tg_reger_api_token"
            >
            </v-text-field>
          </div>
        </div>

        <div v-if="fields.telegram_reger_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('chat_id') }}
              <sb-hint v-if="hints.tg_reger_chat_id">{{
                hints.tg_reger_chat_id
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_reger_chat_id"
              :loading="loading.tg_reger_chat_id"
              name="chat-general"
            >
            </v-text-field>
          </div>
        </div>

        <v-switch
          v-model="fields.telegram_worm_status"
          :loading="loading.telegram_worm_status"
        >
          <template v-slot:label>
            {{ $ml.get('notifications_for_warm') }}
            <sb-hint v-if="hints.telegram_worm_status">{{
              hints.telegram_worm_status
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.telegram_worm_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('token') }}
              <sb-hint v-if="hints.tg_worm_api_token">{{
                hints.tg_worm_api_token
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_worm_api_token"
              :loading="loading.tg_worm_api_token"
            >
            </v-text-field>
          </div>
        </div>

        <div v-if="fields.telegram_worm_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('chat_id') }}
              <sb-hint v-if="hints.tg_worm_chat_id">{{
                hints.tg_worm_chat_id
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_worm_chat_id"
              :loading="loading.tg_worm_chat_id"
              name="chat-general"
            >
            </v-text-field>
          </div>
        </div>

        <v-switch
          v-model="fields.telegram_chat_bot_status"
          :loading="loading.telegram_chat_bot_status"
        >
          <template v-slot:label>
            {{ $ml.get('notifications_for_chat_bot') }}
            <sb-hint v-if="hints.telegram_chat_bot_status">{{
              hints.telegram_chat_bot_status
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="fields.telegram_chat_bot_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('token') }}
              <sb-hint v-if="hints.tg_chat_bot_api_token">{{
                hints.tg_chat_bot_api_token
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_chat_bot_api_token"
              :loading="loading.tg_chat_bot_api_token"
            >
            </v-text-field>
          </div>
        </div>

        <div v-if="fields.telegram_chat_bot_status" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('chat_id') }}
              <sb-hint v-if="hints.tg_chat_bot_chat_id">{{
                hints.tg_chat_bot_chat_id
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-text-field
              v-model="fields.tg_chat_bot_chat_id"
              :loading="loading.tg_chat_bot_chat_id"
              name="chat-general"
            >
            </v-text-field>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        telegram_reger_status: false,
        tg_reger_api_token: '',
        tg_reger_chat_id: '',
        telegram_worm_status: false,
        tg_worm_api_token: '',
        tg_worm_chat_id: '',
        telegram_chat_bot_status: false,
        tg_chat_bot_api_token: '',
        tg_chat_bot_chat_id: '',
        logs_to_db_status: false,
        logs_to_telegram_status: false,
      },
      loading: {
        telegram_reger_status: false,
        tg_reger_api_token: false,
        tg_reger_chat_id: false,
        telegram_worm_status: false,
        tg_worm_api_token: false,
        tg_worm_chat_id: false,
        telegram_chat_bot_status: false,
        tg_chat_bot_api_token: false,
        tg_chat_bot_chat_id: false,
        logs_to_db_status: false,
        logs_to_telegram_status: false,
      },
      hints: {}
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios
      .post('setting/get', { code: 'notifications' })
      .then((response) => {
        this.fields = response.data.fields
        this.hints = response.data.hints
        this.startWatch()
      })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'notifications',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
