<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header color="primary white--text">
          {{ $ml.get('additionally') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <form @submit.prevent="ImportLogs">
            <br />
            <div class="text-subtitle-1">{{ $ml.get('filter') }}</div>
            <v-select
              v-model="filter.status"
              solo
              hide-details
              :items="statusList"
              item-text="status_text"
              item-value="status"
              dense
              return-object
              clearable
              prepend-inner-icon="mdi-sort"
              :label="$ml.get('statuses') + '...'"
              style="max-width: 320px"
              @change="$emit('filter-change', normalizedFilter)"
            ></v-select>

            <br />

            <v-select
              v-model="filter.geo"
              solo
              hide-details
              :items="geoList"
              item-text="geo"
              item-value="geo"
              dense
              return-object
              clearable
              prepend-inner-icon="mdi-sort"
              :label="$ml.get('geo') + '...'"
              style="max-width: 320px"
              @change="$emit('filter-change', normalizedFilter)"
            ></v-select>

            <br />

            <div class="text-subtitle-1">{{ $ml.get('import_accs_from_logs') }}</div>

            <v-file-input v-model="logs" accept=".txt" multiple :label="$ml.get('logs')">
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label small>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="overline grey--text text--darken-3 mx-2"
                >
                  {{ $ml.get('files_left') }} - {{ logs.length - 2 }}
                </span>
              </template>
            </v-file-input>

            <v-btn type="submit" class="ma-2" link dark color="primary">
              <v-icon left>mdi-upload</v-icon>
              {{ $ml.get('import') }}
            </v-btn>
          </form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card color="primary" dark>
        <v-card-title> {{ $ml.get('waiting') }}... </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      logs: null,
      filter: {
        status: null,
        geo: null,
      },
    }
  },
  props: {
    statusList: Array,
    geoList: Array,
  },
  computed: {
    normalizedFilter() {
      return {
        status: this.filter.status != null ? this.filter.status.status : null,
        geo: this.filter.geo != null ? this.filter.geo.geo : null,
      }
    },
  },
  methods: {
    FilterChange() {
      console.log(12)
    },
    ImportLogs() {
      if (this.logs) {
        this.dialog = true
        let formData = new FormData()

        for (let log of this.logs) {
          formData.append('logs[]', log, log.name)
        }

        this.axios
          .post('facebook/import-logs', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.dialog = false
            this.logs = null
            this.$emit('import-logs')
          })
          .catch(() => {
            this.dialog = false
          })
      }
    },
  },
}
</script>
