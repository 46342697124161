<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>{{ $ml.get('authorization') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form @submit.prevent="login" ref="login-form" id="login-form">
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              label="E-mail"
              name="email"
              prepend-icon="mdi-account"
              type="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              :error-messages="passwordErrors"
              :label="$ml.get('password')"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" form="login-form" color="primary">{{ $ml.get('sign_in') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  data() {
    return {
      email: '',
      password: '',
      fields: {},
      isLoginError: false,
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Не валидный e-mail')
      !this.$v.email.required && errors.push('E-mail обязателен')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Пароль обязателен')
      return errors
    },
  },
  methods: {
    login: function () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        let login = this.email
        let password = this.password
        this.$store
          .dispatch('login', { login, password })
          .then(() => this.$router.push('/dashboard'))
          .catch((error) => {
            this.$toast.error(error.response.data.message)
            this.isLoginError = true
          })
      } else {
        console.log('Not Submit')
      }
    },
  },
}
</script>
