import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'us',
  messages: {
    ru: {
      download: 'Скачать',
      photo: 'Фото',
      status: 'Статус',
      actions: 'Действия',
      add: 'Добавить',
      data: 'Данные',
      friends: 'Друзья',
      acc_type: 'Тип аккаунта',
      city: 'Город',
      reg_days: 'Дней с регистрации',
      by: 'По',
      edit: 'Редактировать',
      enabled: 'Включен',
      disabled: 'Выключен',
      all: 'Все',
      valid: 'Валидные',
      proxy: 'Прокси',
      not_valid: 'Не валидный',
    },
    us: {
      download: 'Download',
      photo: 'Download',
      status: 'Status',
      actions: 'Actions',
      add: 'Add',
      data: 'Data',
      friends: 'Friends',
      acc_type: 'Account type',
      city: 'City',
      reg_days: 'Days from registration',
      by: 'By',
      edit: 'Edit',
      enabled: 'Enabled',
      disabled: 'Disabled',
      all: 'All',
      valid: 'Valid',
      not_valid: 'Not valid',
      proxy: 'Proxy',
    },
    es: {
      download: 'Descargar',
      photo: 'Foto',
      status: 'Estado',
      actions: 'Comportamiento',
      add: 'Añadir',
      data: 'Datos',
      friends: 'Amigos',
      acc_type: 'Tipo de cuenta',
      city: 'Ciudad',
      reg_days: 'Días desde el registro',
      by: 'Por',
      edit: 'Editar',
      enabled: 'Activado',
      disabled: 'discapacitado',
      all: 'Todo',
      valid: 'Válido',
      proxy: 'Proxy',
      not_valid: 'No es válido',
    }
  }
})
