import LayoutSettings from '@/layouts/Settings'

import Autoupload from '@/views/Autoupload'
import Farm from '@/views/Settings/Farm'
import Checkpoint from '@/views/Settings/Checkpoint'
import Zrd from '@/views/Settings/Zrd'
import Inviter from '@/views/Settings/Inviter'
import InviterPublic from '@/views/Settings/InviterPublic'
import Messenger from '@/views/Settings/Messenger'
import Posting from '@/views/Settings/Posting'
import SmsServices from '@/views/Settings/SmsServices'
import TemplatesAutoControl from '@/views/Settings/TemplatesAutoControl'

import Accounts from '@/views/Settings/Accounts'
import Notifications from '@/views/Settings/Notifications'

const routes = [
  {
    path: '/settings',
    component: LayoutSettings,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/autoupload',
        name: 'Автозалив',
        component: Autoupload,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'farm',
        name: 'Farm',
        component: Farm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'checkpoint',
        name: 'Checkpoint',
        component: Checkpoint,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'zrd',
        name: 'Zrd',
        component: Zrd,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'inviter',
        name: 'Inviter',
        component: Inviter,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'inviter-public',
        name: 'InviterPublic',
        component: InviterPublic,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'messenger',
        name: 'Messenger',
        component: Messenger,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'posting',
        name: 'Posting',
        component: Posting,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'sms-services',
        name: 'SmsServices',
        component: SmsServices,
        meta: {
          title: 'Настройки - SMS сервисы',
          requiresAuth: true
        }
      },
      {
        path: '/settings/accounts',
        name: 'Accounts',
        component: Accounts,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/settings/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/settings/templates-auto-control',
        name: 'TemplatesAutoControl',
        component: TemplatesAutoControl,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
]

export default routes
