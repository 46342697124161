<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-access-point-check</v-icon>
      <span class="title font-weight-light">{{ $ml.get('checkpoint_settings') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch
        v-model="fields.get_phone_to_account_with_invalid_phone"
        :loading="loading.get_phone_to_account_with_invalid_phone"
      >
        <template v-slot:label>
          {{ $ml.get('receive_accounts_phone') }}
          <sb-hint v-if="hints.get_phone_to_account_with_invalid_phone">{{
            hints.get_phone_to_account_with_invalid_phone
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-show="fields.get_phone_to_account_with_invalid_phone"
        v-model="fields.should_be_friends_on_account"
        class="mt-0"
        :loading="loading.should_be_friends_on_account"
      >
        <template v-slot:label>
          {{ $ml.get('account_friends') }}
          <sb-hint v-if="hints.should_be_friends_on_account">{{
            hints.should_be_friends_on_account
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        class="mt-0"
        v-model="fields.unban_accounts_blocked_status"
        :loading="loading.unban_accounts_blocked_status"
      >
        <template v-slot:label>
          {{ $ml.get('unban_accounts_blocked_status') }}
          <sb-hint v-if="hints.unban_accounts_blocked_status">{{
            hints.unban_accounts_blocked_status
          }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        v-show="fields.get_phone_to_account_with_invalid_phone"
        v-model="fields.days_after_reg_to_get_phone"
        :min="0"
        :max="100"
        step="1"
        thumb-label
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('days_since_reg') }}
          <sb-hint v-if="hints.days_after_reg_to_get_phone">{{ hints.days_after_reg_to_get_phone }}</sb-hint>
        </template>
      </v-slider>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        get_phone_to_account_with_invalid_phone: false,
        should_be_friends_on_account: true,
        unban_accounts_blocked_status: false,
        days_after_reg_to_get_phone: 0,
      },
      loading: {
        get_phone_to_account_with_invalid_phone: false,
        should_be_friends_on_account: false,
        unban_accounts_blocked_status: false,
        days_after_reg_to_get_phone: false
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 300)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'checkpoint' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'checkpoint',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
