<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-message-processing</v-icon>
      <span class="title font-weight-light">{{ $ml.get('sms_services') }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-switch
          v-model="fields.take_phone_by_ip"
          :loading="loading.take_phone_by_ip"
        >
          <template v-slot:label>
            {{ $ml.get('phone_based_on_ip') }}
            <sb-hint v-if="hints.take_phone_by_ip">{{
              hints.take_phone_by_ip
            }}</sb-hint>
          </template>
        </v-switch>

        <v-slider
          v-model="fields.how_long_wait_sms"
          :min="1"
          :max="20"
          step="1"
          thumb-label
          ticks
        >
          <template v-slot:label>
            {{ $ml.get('sms_waiting_time') }}
            <sb-hint v-if="hints.how_long_wait_sms">{{
              hints.how_long_wait_sms
            }}</sb-hint>
          </template>
        </v-slider>

        <v-switch
          v-model="fields.use_phone_multiple_times_for_reg"
          :loading="loading.use_phone_multiple_times_for_reg"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('number_several_times_reg') }}
            <sb-hint v-if="hints.use_phone_multiple_times_for_reg">{{
              hints.use_phone_multiple_times_for_reg
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          v-model="fields.use_phone_multiple_times_for_unlock"
          :loading="loading.use_phone_multiple_times_for_unlock"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('number_several_times_unlock') }}
            <sb-hint v-if="hints.use_phone_multiple_times_for_unlock">{{
              hints.use_phone_multiple_times_for_unlock
            }}</sb-hint>
          </template>
        </v-switch>

        <v-switch
          v-model="fields.generate_phone"
          :loading="loading.generate_phone"
          class="mt-0"
        >
          <template v-slot:label>
            {{ $ml.get('generate_phone') }}
            <sb-hint v-if="hints.generate_phone">{{
              hints.generate_phone
            }}</sb-hint>
          </template>
        </v-switch>

        <div v-if="!fields.take_phone_by_ip" class="d-flex align-center">
          <div class="pr-4">
            <div class="text-subtitle-1">
              {{ $ml.get('countries') }}
              <sb-hint v-if="hints.phone_country_code">{{
                hints.phone_country_code
              }}</sb-hint>
            </div>
          </div>
          <div class="flex-grow-1">
            <v-select
              class="mt-0"
              v-model="fields.phone_country_code"
              :items="country_codes"
              item-text="country"
              item-value="code"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 5" small>
                  <span>{{ item.country }}</span>
                </v-chip>
                <span v-if="index === 5" class="grey--text caption">
                  (+ {{ $ml.get('more') }} {{ fields.phone_country_code.length - 5 }})
                </span>
              </template>
            </v-select>
          </div>
        </div>

        <div
          v-if="fields.take_phone_by_ip || fields.phone_country_code.length > 0"
        >
          <v-text-field
            v-model="fields.smska_net"
            :loading="loading.smska_net"
            label="smska.net"
          ></v-text-field>

          <v-text-field
            v-model="fields.sms_reg"
            :loading="loading.sms_reg"
            label="smsreg"
          ></v-text-field>

          <v-text-field
            v-model="fields.simsms_org"
            :loading="loading.simsms_org"
            label="simsms.org"
          ></v-text-field>

          <v-text-field
            v-model="fields.get_sms_online"
            :loading="loading.get_sms_online"
            label="GetSmsOnline"
          ></v-text-field>

          <v-text-field
            v-model="fields.give_sms"
            :loading="loading.give_sms"
            label="GiveSms"
          ></v-text-field>

          <v-text-field
            v-model="fields.virtual_sms"
            :loading="loading.virtual_sms"
            label="VirtualSms"
          ></v-text-field>

          <v-text-field
            v-model="fields.five_sim_sms"
            :loading="loading.five_sim_sms"
            label="FiveSimSms"
          ></v-text-field>

          <v-text-field
            v-model="fields.sms_actiwator"
            :loading="loading.sms_actiwator"
            label="SmsActiwator"
          ></v-text-field>

          <v-text-field
            v-model="fields.sms_activate"
            :loading="loading.sms_activate"
            label="SmsActivate"
          ></v-text-field>

          <v-text-field
            v-model="fields.text_now"
            :loading="loading.text_now"
            label="TextNow"
          ></v-text-field>

          <v-text-field
            v-model="fields.text_now_developer"
            :loading="loading.text_now_developer"
            label="TextNowDeveloper"
          ></v-text-field>

          <v-text-field
            v-model="fields.sms_hub"
            :loading="loading.sms_hub"
            label="SmsHub"
          ></v-text-field>

          <v-text-field
            v-model="fields.vak_sms_com"
            :loading="loading.vak_sms_com"
            label="vak-sms.com"
          ></v-text-field>

          <v-text-field
            v-model="fields.activation_pw"
            :loading="loading.activation_pw"
            label="Activation PW"
          ></v-text-field>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        take_phone_by_ip: false,
        how_long_wait_sms: 3,
        use_phone_multiple_times_for_reg: false,
        use_phone_multiple_times_for_unlock: false,
        generate_phone: false,
        phone_country_code: '',
        smska_net: '',
        sms_reg: '',
        simsms_org: '',
        get_sms_online: '',
        give_sms: '',
        virtual_sms: '',
        five_sim_sms: '',
        sms_actiwator: '',
        sms_activate: '',
        text_now: '',
        text_now_developer: '',
        sms_hub: '',
        vak_sms_com: '',
        activation_pw: '',
      },
      loading: {
        take_phone_by_ip: false,
        how_long_wait_sms: false,
        use_phone_multiple_times_for_reg: false,
        use_phone_multiple_times_for_unlock: false,
        generate_phone: false,
        phone_country_code: false,
        smska_net: false,
        sms_reg: false,
        simsms_org: false,
        get_sms_online: false,
        give_sms: false,
        virtual_sms: false,
        five_sim_sms: false,
        sms_actiwator: false,
        sms_activate: false,
        text_now: false,
        text_now_developer: false,
        sms_hub: false,
        vak_sms_com: false,
        activation_pw: false,
      },
      hints: {},
      country_codes: [
        { country: 'Россия', code: '0' },
        { country: 'Украина', code: '1' },
        { country: 'Казахстан', code: '2' },
        { country: 'Китай', code: '3' },
        { country: 'Филиппины', code: '4' },
        { country: 'Мьянма', code: '5' },
        { country: 'Индонезия', code: '6' },
        { country: 'Малайзия', code: '7' },
        { country: 'Кения', code: '8' },
        { country: 'Танзания', code: '9' },
        { country: 'Вьетнам', code: '10' },
        { country: 'Кыргызстан', code: '11' },
        { country: 'США (виртуальные)', code: '12' },
        { country: 'Израиль', code: '13' },
        { country: 'Гонконг', code: '14' },
        { country: 'Польша', code: '15' },
        { country: 'Англия', code: '16' },
        { country: 'Мадагаскар', code: '17' },
        { country: 'Дем. Конго', code: '18' },
        { country: 'Нигерия', code: '19' },
        { country: 'Египет', code: '21' },
        { country: 'Индия', code: '22' },
        { country: 'Ирландия', code: '23' },
        { country: 'Камбоджа', code: '24' },
        { country: 'Лаос', code: '25' },
        { country: 'Гаити', code: '26' },
        { country: 'Кот дИвуар', code: '27' },
        { country: 'Гамбия', code: '28' },
        { country: 'Сербия', code: '29' },
        { country: 'Йемен', code: '30' },
        { country: 'ЮАР', code: '31' },
        { country: 'Румыния', code: '32' },
        { country: 'Колумбия', code: '33' },
        { country: 'Эстония', code: '34' },
        { country: 'Азербайджан', code: '35' },
        { country: 'Канада', code: '36' },
        { country: 'Марокко', code: '37' },
        { country: 'Гана', code: '38' },
        { country: 'Аргентина', code: '39' },
        { country: 'Узбекистан', code: '40' },
        { country: 'Камерун', code: '41' },
        { country: 'Чад', code: '42' },
        { country: 'Германия', code: '43' },
        { country: 'Литва', code: '44' },
        { country: 'Хорватия', code: '45' },
        { country: 'Швеция', code: '46' },
        { country: 'Ирак', code: '47' },
        { country: 'Нидерланды', code: '48' },
        { country: 'Латвия', code: '49' },
        { country: 'Австрия', code: '50' },
        { country: 'Беларусь', code: '51' },
        { country: 'Таиланд', code: '52' },
        { country: 'Сауд. Аравия', code: '53' },
        { country: 'Мексика', code: '54' },
        { country: 'Тайвань', code: '55' },
        { country: 'Испания', code: '56' },
        { country: 'Иран', code: '57' },
        { country: 'Алжир', code: '58' },
        { country: 'Бангладеш', code: '60' },
        { country: 'Сенегал', code: '61' },
        { country: 'Турция', code: '62' },
        { country: 'Чехия', code: '63' },
        { country: 'Шри-Ланка', code: '64' },
        { country: 'Перу', code: '65' },
        { country: 'Пакистан', code: '66' },
        { country: 'Новая Зеландия', code: '67' },
        { country: 'Гвинея', code: '68' },
        { country: 'Мали', code: '69' },
        { country: 'Венесуэла', code: '70' },
        { country: 'Эфиопия', code: '71' },
        { country: 'Монголия', code: '72' },
        { country: 'Бразилия', code: '73' },
        { country: 'Афганистан', code: '74' },
        { country: 'Уганда', code: '75' },
        { country: 'Ангола', code: '76' },
        { country: 'Кипр', code: '77' },
        { country: 'Франция', code: '78' },
        { country: 'Папуа-Новая Гвинея', code: '79' },
        { country: 'Мозамбик', code: '80' },
        { country: 'Непал', code: '81' },
        { country: 'Бельгия', code: '82' },
        { country: 'Болгария', code: '83' },
        { country: 'Венгрия', code: '84' },
        { country: 'Молдова', code: '85' },
        { country: 'Италия', code: '86' },
        { country: 'Парагвай', code: '87' },
        { country: 'Гондурас', code: '88' },
        { country: 'Тунис', code: '89' },
        { country: 'Никарагуа', code: '90' },
        { country: 'Тимор-Лесте', code: '91' },
        { country: 'Боливия', code: '92' },
        { country: 'Коста Рика', code: '93' },
        { country: 'Гватемала', code: '94' },
        { country: 'ОАЭ', code: '95' },
        { country: 'Зимбабве', code: '96' },
        { country: 'Пуэрто-Рико', code: '97' },
        { country: 'Судан', code: '98' },
        { country: 'Того', code: '99' },
        { country: 'Кувейт', code: '100' },
        { country: 'Сальвадор', code: '101' },
        { country: 'Ливия', code: '102' },
        { country: 'Ямайка', code: '103' },
        { country: 'Тринидад и Тобаго', code: '104' },
        { country: 'Эквадор', code: '105' },
        { country: 'Свазиленд', code: '106' },
        { country: 'Оман', code: '107' },
        { country: 'Босния и Герцеговина', code: '108' },
        { country: 'Доминиканская Республика', code: '109' },
        { country: 'Сирия', code: '110' },
        { country: 'Катар', code: '111' },
        { country: 'Панама', code: '112' },
        { country: 'Куба', code: '113' },
        { country: 'Мавритания', code: '114' },
        { country: 'Сьерра-Леоне', code: '115' },
        { country: 'Иордания', code: '116' },
        { country: 'Португалия', code: '117' },
        { country: 'Барбадос', code: '118' },
        { country: 'Бурунди', code: '119' },
        { country: 'Бенин', code: '120' },
        { country: 'Бруней', code: '121' },
        { country: 'Багамы', code: '122' },
        { country: 'Ботсвана', code: '123' },
        { country: 'Доминика', code: '126' },
        { country: 'Гренада', code: '127' },
        { country: 'Грузия', code: '128' },
        { country: 'Греция', code: '129' },
        { country: 'Гвинея-Бисау', code: '130' },
        { country: 'Гайана', code: '131' },
        { country: 'Коморы', code: '133' },
        { country: 'Сент-Китс и Невис', code: '134' },
        { country: 'Либерия', code: '135' },
        { country: 'Лесото', code: '136' },
        { country: 'Малави', code: '137' },
        { country: 'Намибия', code: '138' },
        { country: 'Нигер', code: '139' },
        { country: 'Руанда', code: '140' },
        { country: 'Суринам', code: '142' },
        { country: 'Таджикистан', code: '143' },
        { country: 'Бахрейн', code: '145' },
        { country: 'Реюньон', code: '146' },
        { country: 'Замбия', code: '147' },
        { country: 'Армения', code: '148' },
        { country: 'Сомали', code: '149' },
        { country: 'Конго', code: '150' },
        { country: 'Буркина-Фасо', code: '152' },
        { country: 'Ливан', code: '153' },
        { country: 'Габон', code: '154' },
        { country: 'Албания', code: '155' },
        { country: 'Маврикий', code: '157' },
        { country: 'Бутан', code: '158' },
        { country: 'Мальдивы', code: '159' },
        { country: 'Туркменистан', code: '161' },
        { country: 'Экваториальная Гвинея', code: '167' },
        { country: 'Антигуа и Барбуда', code: '169' },
        { country: 'Дания', code: '172' },
        { country: 'Норвегия', code: '174' },
        { country: 'Австралия', code: '175' },
        { country: 'Эритрея', code: '176' },
        { country: 'Южный Судан', code: '177' },
        { country: 'Сан-Томе и Принсипи', code: '178' },
        { country: 'Аруба', code: '179' },
        { country: 'Монтсеррат', code: '180' },
        { country: 'Ангилья', code: '181' },
        { country: 'Северная Македония', code: '183' },
        { country: 'Республика Сейшелы', code: '184' },
        { country: 'Новая Каледония', code: '185' },
        { country: 'Кабо-Верде', code: '186' },
        { country: 'США', code: '187' },
        { country: 'Палестина', code: '188' },
        { country: 'Фиджи', code: '189' },
        { country: 'Южная Корея', code: '190' },
        { country: 'Западная Сахара', code: '192' },
      ],
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 700)
  },
  mounted() {
    this.axios
      .post('setting/get', { code: 'sms-services' })
      .then((response) => {
        console.log(this.fields.phone_country_code.length)
        this.fields = response.data.fields
        this.hints = response.data.hints
        console.log(this.fields.phone_country_code.length)
        this.startWatch()
      })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'sms-services',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
