<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-account-multiple-plus</v-icon>
      <span class="title font-weight-light">{{ $ml.get('inviter_setting') }}</span>
    </v-card-title>
    <v-card-text>
      <v-switch v-model="fields.inviter" :loading="loading.inviter">
        <template v-slot:label>
          {{ $ml.get('inviter') }}
          <sb-hint v-if="hints.inviter">{{ hints.inviter }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        v-if="fields.inviter"
        v-model="fields.day_to_start_inviter"
        :min="1"
        :max="10"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('inviter_launch_day') }}
          <sb-hint v-if="hints.day_to_start_inviter">{{
            hints.day_to_start_inviter
          }}</sb-hint>
        </template>
      </v-slider>

      <v-switch
        v-if="fields.inviter"
        v-model="fields.check_white_list_names"
        :loading="loading.check_white_list_names"
      >
        <template v-slot:label>
          {{ $ml.get('check_white_list') }}
          <sb-hint v-if="hints.check_white_list_names">{{
            hints.check_white_list_names
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-if="fields.inviter"
        v-model="fields.check_black_list_names"
        :loading="loading.check_black_list_names"
      >
        <template v-slot:label>
          {{ $ml.get('check_black_list') }}
          <sb-hint v-if="hints.check_black_list_names">{{
            hints.check_black_list_names
          }}</sb-hint>
        </template>
      </v-switch>

      <div v-if="fields.inviter" class="d-flex align-center">
        <div class="pr-4">
          <div class="text-subtitle-1">
            {{ $ml.get('geo') }}
            <sb-hint v-if="hints.inviter_geo">{{ hints.inviter_geo }}</sb-hint>
          </div>
        </div>
        <div class="flex-grow-1">
          <v-textarea filled v-model="fields.inviter_geo"> </v-textarea>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        inviter: '',
        day_to_start_inviter: '',
        check_white_list_names: '',
        check_black_list_names: '',
        inviter_geo: '',
      },
      loading: {
        inviter: false,
        day_to_start_inviter: false,
        check_white_list_names: false,
        check_black_list_names: false,
        inviter_geo: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 200)
  },
  mounted() {
    this.axios
      .post('setting/get', { code: 'general' })
      .then((response) => {
        this.fields = response.data.fields
        this.hints = response.data.hints
        this.startWatch()
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'general',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          vm.loading[data.key] = false
        })
        .catch(function () {
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
