<template>
  <v-row>
    <v-col md="4" cols="12">
      <v-card class="mb-6" dark color="blue darken-1">
        <v-card-title>
          <v-icon large left> mdi-account </v-icon>
          <span class="title" v-text="$ml.get('total')" />
        </v-card-title>
        <v-card-text class="display-3">{{ total_profiles }}</v-card-text>
      </v-card>

      <v-card class="mb-6" dark color="green darken-1">
        <v-card-title>
          <v-icon large left> mdi-account </v-icon>
          <span class="title">{{ $ml.get('valid') }}</span>
        </v-card-title>
        <v-card-text class="display-3">{{ valid }}</v-card-text>
      </v-card>

      <v-card class="mb-6" dark color="orange darken-1">
        <v-card-title>
          <v-icon large left> mdi-account </v-icon>
          <span class="title">{{ $ml.get('invalid') }}</span>
        </v-card-title>
        <v-card-text class="display-3">{{ invalid }}</v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <v-alert type="info">
        {{ $ml.get('addFriends') }} <strong>{{ friends }}</strong>
      </v-alert>
      <v-alert type="info">
        {{ $ml.get('sentMessages') }} <strong>{{ messages }}</strong>
      </v-alert>
      <v-simple-table color="cyan darken-1">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $ml.get('status') }}</th>
              <th class="text-left">{{ $ml.get('number') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in validStatuses" :key="item.status">
              <td><v-badge
                  dot
                  color="green"
                  inline
                  left
                  bordered
                ></v-badge> {{ item.text }}</td>
              <td>{{ item.counter }}</td>
            </tr>
            <tr v-for="item in invalidStatuses" :key="item.status">
              <td>
                <v-badge
                  dot
                  color="warning"
                  inline
                  left
                  bordered
                ></v-badge>
              {{ item.text }}</td>
              <td>{{ item.counter }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      total_profiles: 0,
      valid: 0,
      invalid: 0,
      friends: 0,
      messages: 0,
      validStatuses: [],
      invalidStatuses: [],
    }
  },
  mounted() {
    this.getData()
  },
  created() {
    this.interval = setInterval(() => this.getData(), 10000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    getData() {
      this.axios
        .post('stats/info')
        .then((response) => {
          // console.log(response)
          this.total_profiles = response.data.total_profiles
          this.valid = response.data.valid
          this.invalid = response.data.invalid
          this.friends = response.data.friends
          this.messages = response.data.messages
          this.validStatuses = response.data.statuses.valid
          this.invalidStatuses = response.data.statuses.invalid
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>
