<template>
  <v-card>
    <v-card-title>
      <v-icon large left>mdi-flash-alert</v-icon>
      <span class="title font-weight-light">{{ $ml.get('zrd_settings') }}</span>
    </v-card-title>

    <v-card-text>
      <v-switch
        v-model="fields.ads_get_token_status"
        :loading="loading.ads_get_token_status"
      >
        <template v-slot:label>
          {{ $ml.get('zrd_eaab') }}
          <sb-hint v-if="hints.ads_get_token_status">{{
            hints.ads_get_token_status
          }}</sb-hint>
        </template>
      </v-switch>

      <v-slider
        class="mt-6"
        v-model="fields.ads_get_token_day_start"
        :min="0"
        :max="30"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('start_day') }}
          <sb-hint v-if="hints.ads_get_token_day_start">{{
            hints.ads_get_token_day_start
          }}</sb-hint>
        </template>
      </v-slider>

      <v-slider
        class="mt-6"
        v-model="fields.frequency_zrd_check"
        :min="1"
        :max="24"
        persistent-hint
        step="1"
        thumb-label="always"
        ticks
      >
        <template v-slot:label>
          {{ $ml.get('zrd_check_period') }}
          <sb-hint v-if="hints.frequency_zrd_check">{{
            hints.frequency_zrd_check
          }}</sb-hint>
        </template>
      </v-slider>

      <v-switch
        v-model="fields.pass_only_zrd"
        :loading="loading.pass_only_zrd"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('zrd_only') }}
          <sb-hint v-if="hints.pass_only_zrd">{{
            hints.pass_only_zrd
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-model="fields.ads_get_phone"
        :loading="loading.ads_get_phone"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('get_number') }}
          <sb-hint v-if="hints.ads_get_phone">{{
            hints.ads_get_phone
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-model="fields.ads_try_create_BM"
        :loading="loading.ads_try_create_BM"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('try_create_bm') }}
          <sb-hint v-if="hints.ads_try_create_BM">{{
            hints.ads_try_create_BM
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-model="fields.create_only_FP"
        :loading="loading.create_only_FP"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('fp_only') }}
          <sb-hint v-if="hints.create_only_FP">{{
            hints.create_only_FP
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-model="fields.get_docs_from_diasPro"
        :loading="loading.get_docs_from_diasPro"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('take_docs_from') }} dias.pro
          <sb-hint v-if="hints.get_docs_from_diasPro">{{
            hints.get_docs_from_diasPro
          }}</sb-hint>
        </template>
      </v-switch>

      <v-switch
        v-model="fields.draw_docs_by_soft"
        :loading="loading.draw_docs_by_soft"
        class="mt-0"
      >
        <template v-slot:label>
          {{ $ml.get('software_render') }}
          <sb-hint v-if="hints.draw_docs_by_soft">{{
            hints.draw_docs_by_soft
          }}</sb-hint>
        </template>
      </v-switch>

      <v-text-field
        v-model="fields.docs_from_folder"
        :loading="loading.docs_from_folder"
        :label="$ml.get('docs_from_folder')"
        :placeholder="$ml.get('folder_path')"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-model="fields.background_path"
        :loading="loading.background_path"
        :label="$ml.get('backgrounds')"
        :placeholder="$ml.get('folder_path')"
        outlined
        dense
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import SbHint from '@/components/Hint.vue'
import _ from 'lodash'

export default {
  data() {
    return {
      fields: {
        ads_get_token_status: false,
        ads_get_token_day_start: 0,
        ads_get_phone: false,
        ads_try_create_BM: false,
        frequency_zrd_check: 6,
        create_only_FP: false,
        pass_only_zrd: false,
        get_docs_from_diasPro: false,
        draw_docs_by_soft: false,
        docs_from_folder: '',
      },
      loading: {
        ads_get_token_status: false,
        ads_get_token_day_start: false,
        ads_get_phone: false,
        ads_try_create_BM: false,
        frequency_zrd_check: false,
        create_only_FP: false,
        pass_only_zrd: false,
        get_docs_from_diasPro: false,
        draw_docs_by_soft: false,
        docs_from_folder: false,
      },
      hints: {},
    }
  },
  created: function () {
    this.debouncedSaveThis = _.debounce(this.saveThis, 300)
  },
  mounted() {
    this.axios.post('setting/get', { code: 'zrd' }).then((response) => {
      this.fields = response.data.fields
      this.hints = response.data.hints
      this.startWatch()
    })
  },
  methods: {
    saveThis(field, value) {
      let data = {
        code: 'zrd',
        key: field,
        value: value,
      }

      var vm = this

      this.axios
        .post('setting/set', data)
        .then(function () {
          console.log('ok')
          vm.loading[data.key] = false
        })
        .catch(function () {
          console.log('not ok')
          vm.loading[data.key] = false
        })
    },
    startWatch() {
      for (var index in this.fields) {
        let field = index.toString()
        this.$watch('fields.' + field, (value) => {
          this.loading[field] = true
          this.debouncedSaveThis(field, value)
        })
      }
    },
  },
  components: {
    SbHint,
  },
}
</script>
