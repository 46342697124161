<template>
  <v-row justify="start" align="start">
    <v-col cols="12">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="profiles"
        item-key="id"
        show-expand
        show-select
        :options.sync="options"
        :loading="loading"
        :page.sync="page"
        :single-expand="true"
        :expanded.sync="expanded"
        :items-per-page="itemsPerPage"
        :server-items-length="totalProfiles"
        :calculate-widths="true"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $ml.get('accounts') }} ({{ totalProfiles }})</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-select
              v-model="sortBy"
              solo
              hide-details
              :items="sortTypes"
              item-text="title"
              item-value="field"
              dense
              return-object
              clearable
              prepend-inner-icon="mdi-sort"
              :label="$ml.get('sortby')"
              style="max-width: 320px"
              @change="getDataFromApi"
            ></v-select>
            <v-btn-toggle
              class="ml-2"
              dense
              v-model="sortDesc"
              mandatory
              @change="getDataFromApi"
            >
              <v-btn icon small depressed :value="false">
                <v-icon small>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon small depressed :value="true">
                <v-icon small>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-select
              v-model="perPage"
              solo
              class="ml-2"
              hide-details
              :items="perPageItems"
              item-text="title"
              item-value="count"
              dense
              return-object
              clearable
              prepend-inner-icon="mdi-counter"
              :label="$ml.get('onpage') + ' (10)'"
              style="max-width: 200px"
              @change="getDataFromApi"
            ></v-select>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="toggleAll(1)"
                >
                  <v-icon>mdi-play</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('enable') }} {{ $ml.get('all') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="toggleAll(0)"
                >
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('disable') }} {{ $ml.get('all') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="downloadFile()"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('download') }} {{ $ml.get('all') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  link
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="uploadFile"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
              <span>{{ $ml.get('upload') }}</span>
            </v-tooltip>
          </v-toolbar>

          <v-toolbar v-if="selected.length > 0" dense flat color="white">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  small
                  rounded
                  dark
                  color="red"
                  @click="removeProfiles"
                >
                  <v-icon left> mdi-delete</v-icon>
                  {{ $ml.get('delete') }}
                </v-btn>
              </template>
              <span>{{ $ml.get('delete') }} {{ $ml.get('selected') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  dark
                  color="primary"
                  @click="downloadFile(selected)"
                >
                  <v-icon left> mdi-download</v-icon>
                  {{ $ml.get('download') }}
                </v-btn>
              </template>
              <span>{{ $ml.get('download') }} {{ $ml.get('selected') }}</span>
            </v-tooltip>
          </v-toolbar>

          <fb-additional @filter-change="onFilterChange" :status-list="statusList" :geo-list="geoList"/>
        </template>

        <template v-slot:[`item.image_url`]="{ item }" width="80">
          <a :href="'https://facebook.com/' + item.data.ID_akk" target="_blank">
            <v-img :src="item.image_url" contain height="80" width="80"></v-img>
          </a>
        </template>

        <template v-slot:[`item.data`]="{ item }">
          <div>
            <strong>{{ item.data.name }}</strong> ({{ $ml.get('friends') }}:
            {{ item.data.friends }})
          </div>
          <div><strong>{{ $ml.get('city') }}: </strong>{{ item.data.city }}</div>
          <div><strong>{{ $ml.get('days_from_reg') }}: </strong>{{ item.data.days }}</div>
        </template>

        <template v-if="userRole == 'admin'" v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div><strong>Login: </strong>{{ item.data.login }}</div>
            <div><strong>Email: </strong>{{ item.data.email }}</div>
            <div><strong>{{ $ml.get('birthday') }}: </strong>{{ item.data.birthday }}</div>
            <div><strong>ID {{ $ml.get('account') }}: </strong>{{ item.data.ID_akk }}</div>
            <div><strong>{{ $ml.get('friends') }}: </strong>{{ item.data.friends }}</div>
            <div><strong>ID {{ $ml.get('pers_ads_manager') }}: </strong>{{ item.data.phone }}</div>
            <div><strong>{{ $ml.get('bm') }} ID: </strong>{{ item.data.id_bm }}</div>
            <div><strong>{{ $ml.get('invite_link') }} {{ $ml.get('bm') }}: </strong>{{ item.data.link_bm }}</div>
            <div><strong>{{ $ml.get('bm') }} {{ $ml.get('token') }}: </strong>{{ item.data.token_bm }}</div>
            <div><strong>{{ $ml.get('token') }}: </strong>{{ item.data.tokenMess }}</div>
            <div><strong>Fan Page: </strong>{{ item.data.fanpage }}</div>
            <div><strong>User Agent: </strong>{{ item.data.useragent }}</div>
            <div>
              <strong>{{ $ml.get('selfie_photo') }}: </strong>{{ item.data.linksSelfy }}
            </div>
          </td>
        </template>

        <template v-slot:[`item.download`]="{ item }">
          <v-btn
            class="ma-2"
            link
            fab
            dark
            x-small
            color="primary"
            @click="downloadFile(item.id)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small dark>{{
            itemStatus(item.status)
          }}</v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip v-if="item.status == 1" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="toggleStatus(item, 0)"
                >mdi-stop</v-icon
              >
            </template>
            <span>{{ $ml.get('disable') }}</span>
          </v-tooltip>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="toggleStatus(item, 1)"
                >mdi-play</v-icon
              >
            </template>
            <span>{{ $ml.get('enable') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                :color="item.hand_mode ? 'green' : ''"
                v-bind="attrs"
                v-on="on"
                @click="toggleHandMode(item)"
                >mdi-gesture-double-tap</v-icon
              >
            </template>
            <span>{{ item.hand_mode ? $ml.get('disable_hand_mode') : $ml.get('enable_hand_mode') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="red"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="removeProfile(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>{{ $ml.get('delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-col>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept=".xlsx"
      @change="onFileChanged"
    />
  </v-row>
</template>

<script>
import FbAdditional from '@/components/Facebook/AdditionalPanel.vue'

export default {
  data() {
    return {
      expanded: [],
      dialog: false,
      totalProfiles: 0,
      profiles: [],
      loading: true,
      options: {},
      selected: [],
      sortBy: {},
      sortDesc: true,
      perPage: {},
      snackbar: {
        status: false,
        text: '',
        color: 'green',
      },
      page: 1,
      pageCount: 1,
      itemsPerPage: 5,
      geoList: [],
      statusList: [],
      filter: {
        status: null,
        geo: null
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add') : this.$t('edit')
    },
    userRole() {
      return this.$store.getters.getUserRole
    },
    headers () {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id',
          width: '60px',
          sortable: false,
        },
        {
          text: this.$t('photo'),
          align: 'center',
          value: 'image_url',
          width: '120px',
          sortable: false,
        },
        { text: this.$t('data'), align: 'start', value: 'data', sortable: false },
        { text: '', value: 'data-table-expand' },
        {
          text: this.$t('download'),
          align: 'end',
          value: 'download',
          width: '110px',
          sortable: false,
        },
        {
          text: this.$t('status'),
          align: 'start',
          value: 'status',
          width: '90px',
          sortable: false,
        },
        {
          text: this.$t('actions'),
          align: 'end',
          value: 'actions',
          width: '110px',
          sortable: false,
        },
      ]
    },
    sortTypes () {
      return [
        { field: 'status', title: this.$t('status') },
        { field: 'friends', title: this.$t('friends') },
        { field: 'account_type', title: this.$t('acc_type') },
        { field: 'city', title: this.$t('city') },
        { field: 'days', title: this.$t('reg_days') },
      ]
    },
    perPageItems () {
      return [
        { count: '20', title: this.$t('by') +' 20' },
        { count: '30', title: this.$t('by') +' 30' },
        { count: '50', title: this.$t('by') +' 50' },
        { count: '70', title: this.$t('by') +' 70' },
        { count: '100', title: this.$t('by') +' 100' },
      ]
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    page() {
      this.getDataFromApi()
    },
  },
  methods: {
    onFilterChange(filter) {
      console.log(filter)
      this.filter.status = filter.status
      this.filter.geo = filter.geo

      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true

      const status = this.filter.status
      const geo = this.filter.geo

      let data = {
        page: this.page,
      }

      if (this.$route.query.status) {
        data.status = this.$route.query.status
      }

      if (this.sortBy && this.sortBy !== undefined && this.sortBy.field !== undefined) {
        data.sort = this.sortBy.field
        if (this.sortDesc) {
          data.sort_order = 'desc'
        } else {
          data.sort_order = 'asc'
        }
      }

      if (status && status !== undefined) {
        data.status = status
      }

      if (geo && geo !== undefined) {
        data.geo = geo
      }

      if (this.perPage && this.perPage !== undefined && this.perPage.count !== undefined) {
        data.per_page = this.perPage.count
      }

      this.axios.post('facebook/list', data).then((response) => {
        this.profiles = response.data.data
        this.totalProfiles = response.data.total
        this.pageCount = response.data.total_page
        this.loading = false
        this.geoList = response.data.geo
        this.statusList = response.data.statuses
      })
    },
    getColor(status) {
      return status == 1 ? 'green' : 'red'
    },
    itemStatus(status) {
      return status == 1 ? this.$t('enabled') : this.$t('disabled')
    },
    toggleStatus(item, status) {
      let data = {
        profile_id: item.id,
        status: status,
      }

      this.axios.post('facebook/set-activity', data).then(() => {
        item.status = status
      })
    },
    toggleHandMode(item) {
      let data = {
        profile_id: item.id,
        hand_mode: !item.hand_mode,
      }

      this.axios.post('facebook/set-hand-mode', data).then((response) => {
        item.hand_mode = response.data.hand_mode
      })
    },
    toggleAll(status) {
      let data = {
        status: status,
      }

      this.axios.post('facebook/set-activity-all', data).then(() => {
        this.getDataFromApi()
      })
    },
    removeProfile(item) {
      let data = {
        profile_id: item.id,
      }

      this.axios.post('facebook/remove', data).then(() => {
        this.getDataFromApi()
      })
    },
    removeProfiles() {
      let profile_ids = []
      this.selected.forEach((el) => profile_ids.push(el.id))

      let data = {
        profile_ids: profile_ids.join(),
      }

      this.axios.post('facebook/remove', data).then(() => {
        this.selected = []
        this.getDataFromApi()
      })
    },
    downloadFile(profile_id) {
      let file_name = 'profiles-fb.xlsx'
      let data = {
        section: this.$route.params.section,
        url: this.$route.params.table,
      }

      if (profile_id) {
        if (profile_id instanceof Array) {
          let profile_ids = []
          this.selected.forEach((el) => profile_ids.push(el.id))

          data.profile_ids = profile_ids.join()
          file_name = 'profiles-' + profile_ids.join('-') + '.xlsx'
        } else {
          data.profile_id = profile_id
          file_name = 'profiles-' + profile_id + '.xlsx'
        }
      }

      this.axios
        .post('facebook/download', data, {
          responseType: 'blob',
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', file_name)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(() => {
          console.log(123)
        })
    },
    uploadFile() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      let formData = new FormData()

      formData.append('file', e.target.files[0])

      this.axios
        .post('facebook/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.getDataFromApi()
        })

      this.$refs.uploader.value = null
    },
    showDetailed(item) {
      item.showDetailed = true
    },
  },
  components: {
    FbAdditional
  }
}
</script>
